"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Parser = void 0;
const ethers_1 = require("ethers");
const thorchain_1 = require("../../parser/thorchain");
const _1 = require(".");
const routerAbi = [
    'function deposit(address vault, address asset, uint256 amount, string memo)',
    'function depositWithExpiry(address vault, address asset, uint256 amount, string memo, uint256 expiration)',
    'function transferOut(address to, address asset, uint256 amount, string memo)',
    'function transferOutAndCall(address target, address finalAsset, address to, uint256 amountOutMin, string memo)',
    'function swapIn(address tcRouter, address tcVault, string tcMemo, address token, uint256 amount, uint256 amountOutMin, uint256 deadline)',
];
const depositRegex = /^(add|a|\+):([^:]+)(:([^:]+)?)?(:([^:]+)?)?(:([^:]+)?)?$/;
const withdrawRegex = /^(withdraw|wd|-):([^:]+)(:([^:]+)?)?(:([^:]+)?)?$/;
class Parser {
    constructor(args) {
        this.abiInterface = new ethers_1.ethers.utils.Interface(routerAbi);
        this.supportedFunctions = {
            depositSigHash: this.abiInterface.getSighash('deposit'),
            depositWithExpirySigHash: this.abiInterface.getSighash('depositWithExpiry'),
            transferOutSigHash: this.abiInterface.getSighash('transferOut'),
            transferOutAndCallSigHash: this.abiInterface.getSighash('transferOutAndCall'),
            swapInSigHash: this.abiInterface.getSighash('swapIn'),
        };
        this.thorchainParser = new thorchain_1.Parser({ midgardUrl: args.midgardUrl });
        this.chainId = args.chainId;
    }
    async parse(tx) {
        if (!tx.inputData)
            return;
        const txSigHash = (0, _1.getSigHash)(tx.inputData);
        const memo = (() => {
            // input data is a function call
            if (Object.values(this.supportedFunctions).some(hash => hash === txSigHash)) {
                const decoded = this.abiInterface.parseTransaction({ data: tx.inputData });
                // failed to decode input data
                if (!decoded)
                    return;
                return decoded.args.memo || decoded.args.tcMemo;
            }
            // input data may be a raw thorchain memo
            const maybeMemo = Buffer.from(tx.inputData.slice(2), 'hex').toString();
            if ([depositRegex, withdrawRegex].some(regex => regex.test(maybeMemo.toLowerCase()))) {
                return maybeMemo;
            }
        })();
        if (!memo)
            return;
        try {
            return await this.thorchainParser.parse(memo);
        }
        catch (err) {
            console.error(`failed to parse tx: ${tx.txid} on ${this.chainId}: ${err}`);
        }
    }
}
exports.Parser = Parser;
