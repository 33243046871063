import { Core } from '@walletconnect/core'
import type { ICore } from '@walletconnect/types'
import type { IWeb3Wallet } from '@walletconnect/web3wallet'
import { Web3Wallet } from '@walletconnect/web3wallet'
import { getConfig } from 'config'

let walletConnectWallet: Promise<IWeb3Wallet>
let core: ICore

// WalletConnect Core singleton
export const getWalletConnectCore = () => {
  if (!core) {
    const walletConnectToDappsProjectId = getConfig().REACT_APP_WALLET_CONNECT_TO_DAPPS_PROJECT_ID
    core = new Core({
      projectId: walletConnectToDappsProjectId,
    })
  }

  return core
}

// WalletConnect Web3Wallet singleton
export const getWalletConnectWallet = () => {
  if (!walletConnectWallet) {
    walletConnectWallet = Web3Wallet.init({
      core: getWalletConnectCore(), // <- pass the shared `core` instance
      metadata: {
        name: 'Bitoveen',
        description:
          'Crypto base for gamers to manage, store, trade, track, buy, and earn crypto in perfect symphony.',
        url: 'https://app.bitoveen.com/',
        icons: ['https://app.bitoveen.com/icon-512x512.png'],
      },
    })
  }

  return walletConnectWallet
}
