import { createIcon } from '@chakra-ui/react'

export const FoxIcon = createIcon({
  displayName: 'FoxIcon',
  path: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98.86 144.04">
    <path
      fill="#659efd"
      d="M96.49 111.57c-.6 3-1.82 5.74-3.49 8.3-1.91 1-4.01.57-6.01.82-7.41.92-14.81 1.94-22.21 2.9q-.94-1.61 1.56-3.61c-1.51.16-2.97 1.42-4.37.36-1-.76.03-1.77.27-2.87-1.69.45-3.2 1.13-4.8 1.49-1.31.3-2.72.77-3.65-.82-.14-2.1-.25-4.2.07-6.29 1.07-1.4 2.62-1.63 4.23-1.69.9.44.82 1.8 1.39 1.93 3.39-2.86 7.39-2.91 11.04-3.88 1.66 1.01.35 1.55-.18 2.13 1.67-.97 3.58-1.11 5.24.18 1.3 1 2.58.99 4.08.95 4.96-.14 9.8-1.35 14.76-1.39.94 0 2.64-.74 2.08 1.5z"
    ></path>
    <path
      fill="#6ea5fd"
      d="M35.42 143.33c-7.19-1.58-13.43-4.98-18.81-9.98 1.94-2.71 5.09-3.85 7.55-5.89 1.08-.3 2.19-1.04 3.18.14.3.35.67.49 1.01.29 2.86-1.62 6.52-.21 9.22-2.53 1.27-1.09 3-1.3 4.57-1.74.93-.26 1.93-.48 2.57.62.28 1.48.16 2.96.09 4.44-.9 2.1-3.04 3.17-3.96 5.07.27.02.48-.51.91-.66.78-.26 1.58-.45 2.04.42.45.84-.13 1.48-.71 2.02-.73.67-1.7.92-2.57 1.33-.95.44-2.07.6-2.63 1.59-.09.26-.05.45.14.55.71.37 2.03-.08 2.08 1.11.04.83-1.04 1.01-1.71 1.36-1.04.54-2.25.81-2.99 1.85z"
    ></path>
    <path
      fill="#68a2fd"
      d="M79 135.92c-4.98 4.07-10.75 6.48-16.93 7.9-3.75.87-6.55-.94-7.82-4.63-.48-1.21-.71-2.44-.42-3.74.12-.29.33-.5.61-.64 5.83-1.63 11.74-2.99 17.36-5.32-2.78.16-3.01-.29-1.79-3.44 2.16-.49 4.43-2.35 6.19.91.36.68 1.81-.07 2.76-.14.97-.08 2.09-.29 2.69.59.68 1-.31 1.78-.84 2.52-.61.87-1.34 1.65-2.09 2.4-3.02 3.06-7.28 2.98-10.98 4.31-1.2.43-2.44.67-3.21 1.83-.26.39-.7.79-.38 1.29.3.48.84.37 1.32.31.7-.09 1.22-.42 1.68-1.01 3.01-3.75 7.5-3.37 11.59-4.04.31-.05.57.46.25.88z"
    ></path>
    <path
      fill="#6597fc"
      d="M70.1 108.62c-1.98.74-3.98 1.4-6.04 1.92-.9.22-3.45-.7-2.74 2.05-.65 0-1.32.09-1.95 0-1.36-.22-1.24-1.2-1-2.19 1.37-3.36 3.57-5.5 7.36-6.24 4.37-.85 8.71-1.93 12.98-3.21 5.92-.83 11.94-.77 17.79-2.13.68-.16 1.35-.1 1.89.43.19 1.24.3 2.47-.08 3.69.12.15.11.29-.04.41-.35.17.14.09-.24.16-5.68 1.11-11.19 2.9-16.84 4.11-1.87.4-3.89.24-5.72.94-1.81.69-3.6-.25-5.38.07z"
    ></path>
    <path
      fill="#67a0fd"
      d="M70.1 126.25q-.13 3.03 2.51 2.71c-1.13 3.06-4.26 3-6.53 3.45-3.25.64-6.2 2.11-9.42 2.71-.62.11-1.23.76-1.8-.06l.06-.39c.32-.81 1.03-1.38 1.47-2.27-.25-.92-1.7-.16-1.89-1.19-.24-2.41 1.23-3.78 3.08-4.74 1.94-1 4.18-1.44 5.63-3.3.24-.31.71-.13 1.04.14 2.17-1.35 4.67-1.2 7.04-1.43 7.24-.7 14.38-2.54 21.73-2.02-.24.36-.49.72-.73 1.08-3.29.03-6.47 1.15-9.77 1.09-.49 0-1.03-.02-1.4.29-4.51 3.69-10.18 3.09-15.39 4.18-.73.15-1.4.73-2.21.45 2.22-.05 4.33-1.09 6.59-.7z"
    ></path>
    <path
      fill="#6099fc"
      d="M70.1 108.62c3-.59 5.99-1.54 9-1.67 3.49-.15 6.52-1.33 9.73-2.45 2.93-1.02 6.27-.83 9.43-1.17-.04 2.86-.64 5.61-1.78 8.23-.1-.9-.53-.95-1.4-.84-5.76.7-11.54 1.28-17.31 1.89-.37.04-.96.04-1.1-.17-2.08-3.05-5-1.95-7.73-1.46.02-1.09 2.84-.51 1.16-2.36z"
    ></path>
    <path
      fill="#659efd"
      d="M70.1 126.25l-7.2 1.17c2.41-1.56 4.55-1.13 6.42-1.77 3.84-1.32 8.16-1.01 11.5-4.22 1.3-1.25 4.03.18 6.08-.65 1.75-.71 3.61-.73 5.37.15-2.6 5.58-6.62 9.95-11.5 13.59-1.69.09-3.04-.17-2.47-2.41 1.02-1.41 2.03-2.81 3.13-4.34-1.86-1.08-3.55.49-5.3.23-.77-.12-1.89.4-1.15-1.32.53-1.23-.79-1.11-1.43-.88-1.14.4-2.28.46-3.46.44z"
    ></path>
    <path
      fill="#9384fc"
      d="M93.63 76.16c.83 1.1 1.46 2.29 1.68 3.67-.51 1.2-1.6 1.29-2.68 1.37-9.19.68-18.26 2.1-27.12 4.71-1.09.32-2.25.72-3.4.1-.08-2.42 2.2-5.02 5.38-5.63 3.9-.74 7.63-2.23 11.61-2.61.59-.06 1.08-.41 1.59-.69.9-.5 1.75-1.1 2.84-1.13 1.91.97 3.79.73 5.82.21 1.32-.34 2.85-.26 4.28 0z"
    ></path>
    <path
      fill="#868bfc"
      d="M95.94 81.53c.74 1.28 1.18 2.65 1.21 4.14-.14.08-.28.16-.41.24-4.88-.28-9.67.59-14.49 1.19-1.91.24-3.29 1.66-5.11 2.05-1.8.39-3.51 1.18-5.41 1.02a.922.922 0 01-.65-.62c.75-2.11 2.4-3.58 3.67-5.33 1.34-1.15 3.08-1.23 4.67-1.49 5.46-.87 10.95-1.69 16.52-1.21z"
    ></path>
    <path
      fill="#8d88fc"
      d="M95.94 81.53c-3.94 1-7.98 1.12-12.01 1.24-3.22.1-6.34.58-9.14 2.35-5 2.21-10.43 2.72-15.68 3.92-1.68.76-3.6 1.16-4.56 3.03-.22.19-.46.24-.73.11v-8.82c.29 0 .53.1.72.32.3.49.37 1.04.41 1.59.09.75-.09 1.5-.05 2.3 1.05-.22 1.93-.78 2.87-1.2 1.33-.5 2.62-1.25 4.12-.66 3.81-.08 7.24-1.83 10.95-2.51 6.82-1.25 13.61-2.47 20.54-2.8.65-.03 1.29-.36 1.93-.56.44.48.69 1.03.63 1.7z"
    ></path>
    <path
      fill="#75a3fd"
      d="M14.88 131.81a20.171 20.171 0 01-4.54-4.99c1.25-1.82 2.29-3.8 4.06-5.21 3.13-.66 5.82-2.39 8.74-3.58 1.54-.28 2.76-1.32 4.2-1.8 1.06-.35 2.3-.9 2.62.95-.69 1.87-2.34 2.81-3.87 3.82-1.86 1.23-3.79 2.37-5.55 3.76-2.2.41-2.53 2.52-3.53 3.99-.7 1.03-.94 2.38-2.13 3.06z"
    ></path>
    <path
      fill="#6aa3fc"
      d="M35.42 143.33c.18-2.64 2.97-1.94 4.15-3.33-1-.66-2.1.15-3.39.14.71-1.86 1.23-3.39 3.38-3.65 1.02-.13 2.01-.71 2.94-1.23.54-.3 1.33-.81.83-1.59-.39-.61-1.06-.26-1.53.12-.71.57-1.49.83-2.77.82 2.01-1.82 2.86-4.33 5.26-5.42 1.25 3.27.36 6.64.45 9.96-1.89 4.42-4.85 5.74-9.32 4.18z"
    ></path>
    <path
      fill="#6ca2fc"
      d="M14.88 131.81c.56-2.54 2.37-4.48 3.3-6.85.26-.65 1.13-1.44 2.07-.47 2.72.15 4.67-1.39 6.54-3.06 1.02-.91 1.93-2.07 3.53-1.16.54.87.16 1.66-.19 2.45-1.44 2.29-4.06 3.29-5.78 5.29-2.44 1.98-4.99 3.81-7.75 5.34-.75-.32-1.33-.83-1.73-1.55z"
    ></path>
    <path
      fill="#9799fc"
      d="M4.51 117.42c-.56-.91-1.08-1.84-1.19-2.93 1.62-1.95 3.57-3.61 4.97-5.75 2.86-2.4 5.69-4.84 9.63-5.23 1.93.97 1.48 2.3.56 3.71-.56.84-1.4 1.31-2.31 1.68-2.13.84-3.78 2.28-5.13 4.1-2.4 1.15-4.46 2.8-6.53 4.42z"
    ></path>
    <path
      fill="#639dfb"
      d="M78.31 132.13c.04 1.61 1.44 1.82 2.47 2.41-.42.68-1.02 1.13-1.77 1.38-3.82-.63-7.09 1.4-10.61 2.18-.24 2.65-2.11 2.84-4.19 2.86-1.77.01-1.85-.87-1-1.96.96-1.24 1.76-2.43 3.68-2.93 3.88-1.02 8.05-1.41 11.42-3.94z"
    ></path>
    <path
      fill="#7790fc"
      d="M97.75 88.46c.7 1.1.59 2.33.55 3.55.28.87.41 1.66-.94 1.15-.54-.2-.99-.61-1.59-.72-6.31-.2-12.39 1.32-18.53 2.35-1.34.22-2.66.74-3.99.03-.63-2.93 1.77-2.94 3.45-3.36 3.45-.85 7-1.21 10.52-1.71 2.22-.82 4.53-.78 6.85-.74 1.26.02 2.5-.07 3.68-.56z"
    ></path>
    <path
      fill="#7c8afa"
      d="M97.14 85.67c.44.31.53.74.44 1.23-.1 1.82-1.08 2.42-2.86 2.35-3.51-.13-6.97.52-10.42 1.06-2.83-.28-5.51 1.12-8.35.78-.35-1.6.94-1.89 1.92-2.46 3.77-2.19 8.05-1.97 12.14-2.5 2.28-.3 4.63-.34 6.95-.41l.18-.06z"
    ></path>
    <path
      fill="#b58ff7"
      d="M2 110.52c-.31-1.04-.61-2.07-.92-3.11.68-2.41 2.45-4.17 3.75-6.21 1.68-1.16 3-3.03 5.4-2.75 1.03 1.38-.22 2.62-.22 3.94-.57 1.74-1.73 3.08-3.03 4.31-1.71 1.21-3.77 1.96-4.98 3.82z"
    ></path>
    <path
      fill="#7197f9"
      d="M53.61 138c.03-13.03.06-26.07.1-39.1 0-1.26.07-2.52.11-3.79.33-.01.59.12.77.4.51.99.4 2.07.45 3.12.01 1.14-.07 2.28.29 3.39.3 1.47.08 3.18 1.81 4.02.36.47.29 1.07-.1 1.29-1.72.92-1.96 2.74-2.74 4.23v7.06c1.57 2.31 1.05 4.96.82 7.38-.29 3.02.15 6.11-.82 9.06 0 1.38-.02 2.75-.03 4.13-.21-.4-.43-.79-.64-1.19z"
    ></path>
    <path
      fill="#9a81fc"
      d="M93.63 76.16c-2.31 1.15-4.93.51-7.31 1.29-1.13.37-2-.32-2.73-1.15 2.36-1.84 4.42-4.26 7.8-4.18.31.27.61.54.92.81.44 1.07.88 2.15 1.32 3.22z"
    ></path>
    <path
      fill="#889dfc"
      d="M7.26 122.32c-.82-.66-1.21-1.59-1.56-2.54 1.03-1.62 2.82-2.44 4.04-3.86 1.96-.85 3.73-1.88 5.2-3.54 1.74-1.95 2.94-1.88 4.33-.25-1.08 2.42-3.04 3.91-5.29 5.11-2.39 1.49-5 2.68-6.72 5.07z"
    ></path>
    <path
      fill="#8e9dfc"
      d="M9.69 116.26c-.59 2.01-2.34 2.71-3.99 3.52-.4-.79-.8-1.58-1.19-2.37 1.19-2.83 3.65-3.92 6.35-4.68.84 1.59-.24 2.53-1.17 3.53z"
    ></path>
    <path
      fill="#82a2fc"
      d="M7.26 122.32c.56-3.76 4.14-3.91 6.53-5.47.83 1.36-.48 2.26-.72 3.39-.65 1.54-2.89 1.82-2.99 3.8-.17.53-.43.97-1.01 1.13-1.13-.62-1.4-1.78-1.8-2.85z"
    ></path>
    <path
      fill="#718df9"
      d="M96.49 91.58c.48.05.86.18 1.12.68.69 1.3.53.02.7-.25.73 3.64.74 7.28 0 10.91-.03-1.23-.07-2.47-.1-3.7-.3-.39-.62-.8-.63-1.29-.04-2.24-1.38-2.72-3.3-2.6-.96.06-1.95.03-2.84-.46-.79-1.98.84-2.2 2-2.57.98-.32 2.11-.12 3.05-.71z"
    ></path>
    <path
      fill="#9e97fb"
      d="M8.52 109.21c-1.25 2.24-2.68 4.3-5.2 5.28-.67-.87-.86-1.92-1.1-2.95.53-1.9 2.44-2.02 3.75-2.92.83-.5 1.59-1.35 2.7-.61.23.44.17.84-.15 1.2z"
    ></path>
    <path
      fill="#7ba4fb"
      d="M9.06 125.18c.21-.42.42-.85.62-1.27 1.21-2.06 2.68-3.74 5.36-3.59.52.88.03 1.39-.67 1.83-1.33 1.57-1.88 3.83-4.05 4.68-.42-.55-.84-1.1-1.27-1.64z"
    ></path>
    <path
      fill="#7e97fd"
      d="M84.17 89.82c3.44-.98 7.02-.88 10.53-1.1 1.49-.09 2.31-.58 2.87-1.81.06.52.12 1.04.17 1.56-.21 1.5-1.35 1.37-2.4 1.35-2.77-.05-5.53-.06-8.25.59-.99-.12-2.1.31-2.93-.59z"
    ></path>
    <path
      fill="#bd92f0"
      d="M5 101.57c-.86 2.24-2.31 4.1-3.91 5.84-.23-1.36-.45-2.72-.68-4.09.17-.34.31-.7.61-.96.53-.39 1.12-.67 1.71-.94.78-.29 1.56-.57 2.28.15z"
    ></path>
    <path
      fill="#ab97fc"
      d="M6.17 109.21c-1.23.92-2.88 1.13-3.95 2.33-.07-.34-.14-.68-.22-1.02 0-3.2 3.08-2.93 4.75-4.25 1.04 1.23-.1 2.02-.58 2.94z"
    ></path>
    <path
      fill="#75affd"
      d="M44.79 121.56c.06 5.67.13 11.34.19 17.01-.08.19-.16.39-.24.58-.96-3.28-.19-6.65-.45-9.96v-5.29c-.44-.41-.58-.92-.52-1.5.05-.28.15-.53.3-.77.22-.2.46-.23.72-.07z"
    ></path>
    <path
      fill="#d797f1"
      d="M.89 102.75c-.16.19-.33.38-.49.57-.07-.6-.15-1.19-.22-1.79.07-1.35.13-2.69.2-4.04 2.17 1.56 1.48 3.4.52 5.26z"
    ></path>
    <path
      fill="#fd254b"
      d="M24.92 8.14c.46.35.91.71 1.37 1.06-.02 1.35.44 2.65.4 4.01-.24 1.47-.21 3.03-1.36 4.2-3.42 1.31-3.89 3.97-3.25 7.14-.03.29-.1.58-.2.86-.64 1.14-1.45 1.96-2.92 1.59-.32-.34-.49-.76-.59-1.21-.2-1.15-.53-2.28-.61-3.45-.32-3.27-.07-6.36 2.61-8.73 1.6-1.75 3.38-3.35 4.55-5.46z"
    ></path>
    <path
      fill="#fb2f42"
      d="M16.72 3.45c2.33.52 4.14 2.14 6.32 2.98.05.18.1.36.14.53-3.17.52-1.96 3.25-2.55 5.08-.52.81-1.61 1.5-2.14.96-.87-.89-.78-.1-1.16.25-1.32 1.19-1.89 3.52-4.39 2.97-.55-.91-.56-1.96-.73-2.96 0-1.13.8-1.71 1.63-2.19 1.94-1.12 2.99-2.56 2.29-4.95-.26-.9-.23-1.92.59-2.67z"
    ></path>
    <path
      fill="#fb2142"
      d="M20.25 12.25c0-.67.18-1.41-.04-2-1.19-3.14.38-3.65 2.97-3.28.58.39 1.16.79 1.73 1.18.33 3.07-2.19 3.94-4.09 5.28-.44-.27-.61-.68-.58-1.18z"
    ></path>
    <path
      fill="#f90a49"
      d="M32.56 14.61c.98 1.16 1.96 2.33 2.94 3.49.4.75.81 1.5 1.21 2.24.18.24.2.49.05.76-1.32-1.56-.75.47-1.18.62-.26.12-.53.11-.79 0-.81-3.19-2.64-5.56-5.59-7.04-.24-.08-.47-.2-.68-.32-.44-.79-1.69-.26-2.03-1.24.36-.96.74-1.88 2.06-1.5.17 0 .33-.02.48-.08 1.17 1.03 2.34 2.05 3.51 3.08z"
    ></path>
    <path
      fill="#f82146"
      d="M28.57 11.61c-.85.38-1.47.98-1.88 1.81-.19.18-.39.18-.58 0 .37-1.39-1.59-2.88.18-4.22 1.08.49 2.11 1.04 2.28 2.41z"
    ></path>
    <path
      fill="#d45eae"
      d="M62.45 51.12c1.51-.12 2.82.48 4.11 1.12.17.8-.47.97-.97 1.24-2.01.75-3.85 1.95-6.01 2.35-1.01.08-2 .33-3.01.39-2.38-.84-.95-1.85 0-2.58 1.72-1.34 3.89-1.74 5.88-2.52z"
    ></path>
    <path
      fill="#d762b2"
      d="M62.45 51.12c-1.31 1.85-3.73 1.7-5.33 2.96-.3.24-2.61.45-.52 1.65-.44 1.54-1.35 1.17-2.32.59-.15-1.53 1.17-2.3 1.76-3.45 1.93-1.35 3.95-2.36 6.41-1.76z"
    ></path>
    <path
      fill="#cb60b7"
      d="M65.4 52.8c.47-.01.91-.1 1.17-.56.77.21 1.55.43 2.32.64.01.15.03.3.05.46.2 2.94-2.13 1.77-3.5 2.17-1.63-.88-1.21-1.79-.04-2.71z"
    ></path>
    <path
      fill="#fc483c"
      d="M.18 4.01c.04-.38.09-.76.13-1.14C2.76-.29 4.34-.65 9.19.85c.21.39.35.8.27 1.25-.16.57-.46 1.12-.93 1.41-3.04 1.88-3.1 4.5-1.91 7.45.87 2.2-.07 4.37-.09 6.55-.01.29-.08.57-.2.84-.57.68-1.09 1.47-2.16.88-.27-.27-.48-.84-.76-.83-3.99.08-3.08-2.81-3.09-5.1 0-1.16-.04-2.31-.3-3.45.09-1.95-.19-3.9.16-5.83z"
    ></path>
    <path
      fill="#fc3a40"
      d="M6.17 18.12v-.59c3.06-2.13 3.56-5.34 3.67-8.71.75-1.24 1.41-2.45 1.14-4.04-.2-1.15.08-2.3 1.06-3.13l1.17-.03c.88.64 1.86 1.03 2.93 1.2.19.21.39.42.58.63 1.62 4.56-.68 7.49-4.1 9.97.33 1.37-.85 1.92-1.48 2.78-.96.93-1.8 1.96-2.73 2.91-1.49 1.53-2.26 1.08-2.24-.99z"
    ></path>
    <path
      fill="#fb3c3c"
      d="M12.04 1.65c.04 1.47-.78 2.83-.12 4.43.5 1.2-.6 2.06-1.65 2.64-1.23-.49-1.38-1.66-1.75-2.71-.3-1.33-.21-2.59.57-3.75l.09-1.4c.97.19 2.05 0 2.85.8z"
    ></path>
    <path
      fill="#f31d5f"
      d="M36.66 21.06c.02-.24.04-.48.05-.72.56.66 1.12 1.31 1.68 1.97l1.62 2.91c.45.96.9 1.93 1.35 2.89 0 .39.01.77.02 1.16-.86 2.13-2.62 2.93-4.73 3.22-.6-.14-1.12-.41-1.47-.94-.36-.68-.41-1.41-.44-2.16-.75-3.1 1.87-5.42 1.91-8.34z"
    ></path>
    <path
      fill="#e93f7c"
      d="M43.77 36.78c.16.09.31.18.47.26.12 1.19-.2 2.44.59 3.52l-.06 1.68c-.16.38-.34.76-.67 1.04-1.33.5-2.29 2.27-4.08 1.2-.23-1.03 0-2.01.3-2.99 1.29-1.47 2.56-2.95 3.46-4.72z"
    ></path>
    <path
      fill="#f55494"
      d="M44.29 42.8c.16-.19.32-.38.48-.56.07.38.13.76.2 1.13-.06 1.18-.13 2.37-.19 3.55-.36.14-.64.05-.84-.29-.56-1.34-.6-2.63.35-3.83z"
    ></path>
    <path
      fill="#ee3b76"
      d="M43.77 36.78c.62 2.62-1.46 3.57-3 4.85-1.63 2.11-4.03 2.9-6.39 3.74-.96-1.43-2.69.2-3.73-.92.07-1.46 1.1-2 2.29-2.25 1.85-.39 3.39-1.45 5.08-2.14 2.09-.85 2.84-2.19 2.52-4.3.16-1.74-.53-3.75 1.52-4.89.17.04.32.1.48.17-.13 1.37.54 2.49 1.12 3.64.04.7.07 1.4.11 2.1z"
    ></path>
    <path
      fill="#a77bf9"
      d="M89.4 68.68c.57.97 1.13 1.95 1.7 2.92-4.42 1.16-9.02 1.3-13.47 2.38-2.15.52-4.14 1.81-6.48 1.54-1.48-1.68.41-2.47.94-3.63.34-.35.73-.63 1.16-.84 4.94-1.55 9.96-2.74 15.14-3.18l1.01.81z"
    ></path>
    <path
      fill="#b675ec"
      d="M82.88 62.24c1.4 1.19 2.79 2.38 4.19 3.57 0 .17.02.33.04.5.13.6-.23.98-.69 1.1-3.53.9-7.04 2-10.75 1.8-.2-.86-.92-1.25-1.57-1.71 1.12-1.83 3.48-2.36 4.51-4.29.23-.16.48-.27.74-.36 1.19-.12 2.26-.99 3.54-.61z"
    ></path>
    <path
      fill="#ab75eb"
      d="M75.37 68.66c3.97-.48 7.97-.84 11.74-2.35.66.33 1.04.89 1.28 1.56-2.7 2.05-6.17 1.15-9.13 2.41-1.92.82-4.14.9-6.23 1.31-.32-1.86 1.69-1.86 2.34-2.94z"
    ></path>
    <path
      fill="#ca6cce"
      d="M74.15 55.75c.2.02.41.05.61.07l2.94 2.34c-.22 1.5-1.54 1.24-2.47 1.46-4.12.96-8.26 1.81-12.25 3.3-.94.35-1.73.77-2.45 1.41-2.12.71-3.83 2.73-6.4 2-.26-.76-.11-1.55-.15-2.33.03-.76-.07-1.53.09-2.29.5-.49.98-.45 1.47.02.14.13-.09.42.22.44-.13-2.17 1.81-2.12 3.03-2.84 1.39-.57 2.8-1.09 4.26-1.44 2.09-.44 4.05-1.29 6.07-1.92 1.64-.51 3.31-1.18 5.03-.21z"
    ></path>
    <path
      fill="#c16acf"
      d="M60.13 63.96c2.03-2.51 5.2-2.5 7.89-3.44 3.12-1.1 6.45-1.6 9.68-2.36.74-.25 1.11.54 1.73.64.01.15.04.3.08.45-.17.86-.77 1.46-1.54 1.49-2.3.08-4.08 1.43-6.08 2.26-2.76-.74-5.32.18-7.88 1-1.27.41-2.5 1.05-3.9.66-.15-.16-.2-.32-.14-.47.06-.15.11-.23.17-.23z"
    ></path>
    <path
      fill="#b46ad0"
      d="M82.88 62.24c-1.08.53-2.08 1.27-3.38 1.13-.35-1.49.37-2.32 1.71-2.77.59.37 1.17.73 1.76 1.1a1 1 0 01-.09.54z"
    ></path>
    <path
      fill="#cd66c7"
      d="M74.15 55.75c-3.95 0-7.3 2.3-11.09 2.92-.48-.8.69-1.26.4-2.03.29-.97 1.19-1.13 1.95-1.49.93-1.1 3.13.3 3.54-1.81 1.7.88 3.79.91 5.2 2.42z"
    ></path>
    <path
      fill="#f3346d"
      d="M42.06 30.86c-1.1 1.71-.58 3.64-.7 5.48-2.54 2.36-5.54 4.09-8.52 5.75-1.57.87-2.74 2.48-4.66 2.7a1 1 0 01-.67-.5c-.53-1.79.24-3.47.42-5.2 1.05-2.68 4-3.06 5.8-4.84.44-.49.63-1.14 1.06-1.64.57-.38 1.16-.65 1.87-.41 1.78-.66 3.26-1.8 4.73-2.94.67.34.57 1.01.68 1.59z"
    ></path>
    <path
      fill="#c177e2"
      d="M81.2 60.6c-.57.92-1.14 1.85-1.71 2.77h-.6c-2.31 1.51-4.87 2.02-7.59 1.89-.72-1.03-.29-1.8.56-2.48 1.57-1.57 3.32-2.64 5.68-2.56.65.02 1.31-.63 1.97-.98.57.45 1.13.9 1.7 1.35z"
    ></path>
    <path
      fill="#9e85fd"
      d="M70.68 75.13c3.29-.71 6.48-1.79 9.77-2.54 3.56-.82 7.07-1 10.65-.98.1.17.19.35.29.52-2.89.85-4.77 3.6-7.8 4.18-.78.39-1.56.78-2.35 1.18-4.2.91-8.41 1.77-12.57 2.87-.67.18-1.45.72-2.15-.03.61-2.36 2.06-4.04 4.16-5.19z"
    ></path>
    <path
      fill="#6aa1fd"
      d="M54.28 135.07v-16.45c3.38.29 6.21-1.96 9.72-2.26-.31 1.52-2.12 2.02-1.86 3.61 2.04.44 3.73-1.06 5.86-1.32-.47 2.44-3.54 2.41-3.77 4.67-1.85 2-4.25 3.02-6.72 4.04-1.68.69-2.62 2.26-2.67 4.19.99 1.18.72 2.35 0 3.53h-.57z"
    ></path>
    <path
      fill="#6b98fd"
      d="M54.28 111.56c-1.1-2.91 1.66-3.68 2.91-5.29-.06-1.89 1.46-2.15 2.73-2.46 5.41-1.28 10.81-2.63 16.21-3.95 1.24-.3 2.51-.43 3.71.23.75 1.81-1.23.78-1.54 1.48-3.94 1.87-8.28 2.37-12.45 3.35-2.88.68-4.95 1.85-6.16 4.52-.27.59-.69.91-1.33.94-1.32.54-2.61 1.18-4.08 1.18z"
    ></path>
    <path
      fill="#68a1fc"
      d="M44.29 122.14v1.76c-3.53.33-6.48 2.22-9.62 3.59-.74.32-1.97 2.21-2.48-.31 0-.02-.22-.06-.27-.01-2.31 2.21-5 .94-7.56.84.76-2.76 3.57-3.48 5.28-5.29 1.88-1.78 4.95-2.25 5.71-5.26.95-.74 1.86-.83 2.7.16.09 2.83-1.63 4.42-3.89 5.61-1.62.85-3.48 1.2-4.74 2.39 2.58-1.72 5.56-2.77 8.85-2.85 1.11.19 2.04-.37 3.04-.67.99-.3 2-.82 2.98.04z"
    ></path>
    <path
      fill="#6b94fc"
      d="M78.31 101.57c.59-.39 1.17-.78 1.76-1.18 3.24-2.86 6.65-5.39 11.14-5.88.86 0 1.72-.02 2.58 0 4.7.1 4.53-.92 4.42 4.7-6.51 1.8-13.22 1.97-19.9 2.35z"
    ></path>
    <path
      fill="#68a2fd"
      d="M54.85 135.07v-3.53c.91.14 1.92-1.19 2.64-.13.76 1.1-.79 1.53-1.18 2.33-.27.55-.96.89-1.46 1.33z"
    ></path>
    <path
      fill="#9a86fd"
      d="M66.58 79.83c4-.13 7.78-1.41 11.64-2.25 1.01-.22 1.99-.9 3.03-.1-2.45 1.78-5.5 1.48-8.23 2.3-4.14 1.25-8.73 1.45-11.13 5.92-1.37.39-2.74.78-4.11 1.18-.66-1.6-2.08-1.67-3.5-1.76 0-.58-.01-1.17-.02-1.75.1-.12.2-.23.3-.35 2.77-1.12 5.5-2.4 8.37-3.23 1.13-.32 2.44-.8 3.64.03z"
    ></path>
    <path
      fill="#c170d5"
      d="M60.13 63.96v.59c.25 1.88-1.12 3.15-1.74 4.7-2.4 1.54-4.16 3.42-3.39 6.6 0 .43-.04.85-.12 1.26-.06.28-.17.54-.32.79-.21.23-.45.28-.73.15-.07-3.33-.15-6.65-.22-9.98l.21-1.21c.06-.26.22-.44.46-.56l5.86-2.35z"
    ></path>
    <path
      fill="#b778e5"
      d="M53.82 78.05h.43c.43.34.62.82.71 1.34.14 1.38.28 2.76-.69 3.94l-.01.04c-.15-.01-.29-.02-.44-.02v-5.29z"
    ></path>
    <path
      fill="#7a93fc"
      d="M54.24 95.12h-.42v-2.94h.43c.81.78.75 1.68.33 2.62-.11.11-.23.22-.34.33z"
    ></path>
    <path
      fill="#8091fd"
      d="M54.28 98.63c-.01-1.17-.02-2.34-.04-3.5l.04-.04c.14-2.79 2.26-3.29 4.36-3.81 3.21-1.67 6.79-1.95 10.24-2.7.84-.18 1.73-.08 2.39.65v.59c-.39 1.4-1.61 2.25-2.25 3.49-1.34 2.07-3.5 2.57-5.71 2.89-3.11.46-5.74 2.67-9.03 2.43z"
    ></path>
    <path
      fill="#898dfd"
      d="M71.27 89.23c-4.46.27-8.78 1.16-12.9 2.94-.89-1.18.41-2.35 0-3.53 5.43-1.4 10.85-2.82 16.42-3.53-1.05 1.48-1.8 3.21-3.52 4.11z"
    ></path>
    <path
      fill="#7f8efd"
      d="M68.92 92.76c.14-1.49 1.26-2.21 2.35-2.94 5.99-1.19 11.49-4.28 17.74-4.48 2.67-.09 5.36-.75 7.96.39-1.61 1.04-3.41 1.19-5.25 1.15-3.89-.09-7.71.62-11.51 1.3-1.67.3-3.35 1.08-4.24 2.81-2.12 1.51-4.5 1.93-7.04 1.76z"
    ></path>
    <path
      fill="#8792fd"
      d="M58.37 88.64c2.54 1.18-.67 2.35 0 3.53-2.09-.05-3.32 1.11-4.09 2.91 0-.97-.02-1.94-.03-2.91-.14-2.95 2.58-2.54 4.12-3.53z"
    ></path>
    <path
      fill="#978dfd"
      d="M54.28 85.12c.19-.03.39-.02.57-.09q3.33-1.3 2.93 1.85c-.87.99-2.18 1.67-3.21 1.17-.78-.39-.22-1.91-.29-2.93z"
    ></path>
    <path
      fill="#6e9efc"
      d="M37.84 118.03c-.78-.83-1.56-.83-2.35 0-1.52 1.32-3.25 2.18-5.28 2.35-1.18-.15-2.12.36-2.71 1.29-1.51 2.39-4.24 2.75-6.45 3.96-1.15.63-.63-.67-.82-1.13 2.59-3.06 6.56-4.3 9.38-7.05.96-1.06 2.19-1.73 3.39-2.46 2.92-1.51 6.13-2.36 9.02-3.95.68-.37 1.43-.63 2.22-.25.34.35.31.79.32 1.23 0 .29-.06.57-.17.83-2.8.95-4.15 3.74-6.56 5.19z"
    ></path>
    <path
      fill="#759dfd"
      d="M33.15 115.67c-1.14.66-2.08 1.7-3.52 1.76-.67-2.17-1.99-.55-2.72-.25-1.23.51-2.25 1.54-3.73 1.42-.42-1.65.67-2.49 1.76-3.34 3-1.13 5.85-2.28 7.23-5.68.65-1.6 2.3-2.64 4.35-1.81.84 1.61-.88 2.57-1 3.93-.48 1.51-1.58 2.64-2.37 3.97z"
    ></path>
    <path
      fill="#79a0fd"
      d="M24.94 115.67c-.59.98-1.17 1.96-1.76 2.94-2.8 1.5-5.48 3.31-8.8 3.53.2-.59.39-1.18.59-1.76-.06-.13-.13-.25-.2-.38.09-.27.26-.5.49-.67 1.81-.99 3.3-2.43 4.99-3.58 1.44-.98 2.84-2.47 4.93-1.68.27.59.21 1.13-.25 1.61z"
    ></path>
    <path
      fill="#679dfc"
      d="M30.22 120.38c1.51-1.34 3.33-2 5.28-2.35-.37 3.55-3.49 3.67-5.87 4.7.2-.78.39-1.57.59-2.35z"
    ></path>
    <path
      fill="#8b98fc"
      d="M9.69 116.26c.39-1.18.78-2.35 1.17-3.53.68-2.56 2.88-3.48 4.95-4.49.77-.38 1.72-.41 2.09-1.39 1.04-.74 2.1-1.45 3.32-1.86 2.21-.88 4-2.95 6.72-2.36.68 2.33-1.34 3.76-1.86 5.69-1.3 3.19-3.98 3.83-7.01 3.82-1.88-1.73-3.08-.13-3.99 1.09-1.4 1.88-3.17 2.78-5.4 3.03z"
    ></path>
    <path
      fill="#9693fd"
      d="M21.42 105.68c-1.17.39-2.34.78-3.52 1.18.68-.98 1.44-1.96 0-2.94.58-2.42 2.42-3.63 4.44-4.67 2.74-1.34 5.32-3.03 8.29-3.9 1.64.39 1.08 1.93 1.52 2.94-.32 1-1.21 1.17-2.05 1.36-3.71.83-6.82 2.53-8.68 6.03z"
    ></path>
    <path
      fill="#a192fc"
      d="M22.59 99.81c-2.11.74-3.06 2.83-4.69 4.11-3.64.85-6.42 3.24-9.38 5.29v-1.17c-.47-1.27.25-2.2 1.17-2.65 3.65-1.78 6.88-4.26 10.41-6.24.43-.24.94-.16 1.42-.05.43.12.81.33 1.08.71z"
    ></path>
    <path
      fill="#9d98fd"
      d="M44.78 87.45v9.99a.863.863 0 01-.84-.41c-.88-2.23-.31-4.55-.45-6.83 0-.81.06-1.62.47-2.35.2-.28.47-.41.82-.39z"
    ></path>
    <path
      fill="#6ea7fd"
      d="M44.29 112.74v-.59c.16-.26.33-.26.5-.01v9.43h-.49c-1.03-1.02-.79-2.34-.84-3.59.02-1.78-.41-3.64.83-5.23z"
    ></path>
    <path
      fill="#ed63ae"
      d="M44.78 50.45c.06 2.74.13 5.48.19 8.23-.06.2-.13.4-.19.6-.34.03-.6-.09-.8-.37-.33-.56-.42-1.19-.46-1.83.07-2.08-.4-4.2.43-6.23.2-.29.48-.43.83-.4z"
    ></path>
    <path
      fill="#85a3fd"
      d="M44.3 97.45c.16 0 .33 0 .49-.02v8.23c-.29.18-.55.13-.76-.14-.16-.25-.28-.53-.34-.83-.14-.75-.14-1.51-.17-2.27.06-1.56-.26-3.15.44-4.65.11-.11.23-.22.34-.33z"
    ></path>
    <path
      fill="#cf7fe7"
      d="M44.78 69.25v7.64c-.33.16-.59.08-.8-.22-.33-.57-.41-1.19-.45-1.83.05-1.74-.32-3.51.43-5.19.2-.29.47-.42.82-.39z"
    ></path>
    <path
      fill="#7aa6fd"
      d="M44.29 105.68c.17 0 .33 0 .5-.01v6.46c-.17 0-.33.01-.5.01v-1.17c-.46-.54-.74-1.16-.76-1.87-.04-1.2-.2-2.44.77-3.42z"
    ></path>
    <path
      fill="#e370c0"
      d="M44.29 59.26h.49v6.45c-.33.16-.59.09-.79-.21-.61-1.16-.42-2.43-.46-3.66.02-.78.05-1.55.43-2.26l.33-.33z"
    ></path>
    <path
      fill="#b78efd"
      d="M44.78 87.45c-.16.01-.33.02-.49.02l-.32-.32c-.44-.83-.4-1.74-.42-2.63.02-.89-.02-1.8.46-2.62a.8.8 0 01.78-.33v5.87z"
    ></path>
    <path
      fill="#c484f6"
      d="M44.78 81.58c-.16 0-.33 0-.49.01l-.33-.33c-.54-1.18-.47-2.4-.2-3.63.11-.3.29-.54.54-.74.16 0 .31 0 .47-.01v4.69z"
    ></path>
    <path
      fill="#db77d1"
      d="M44.3 65.73h.49v3.53h-.49c-.1-.1-.2-.2-.31-.3-.41-.82-.41-1.67-.19-2.54.1-.28.28-.5.51-.69z"
    ></path>
    <path
      fill="#7195fd"
      d="M91.21 94.52c-3.71 1.96-7.43 3.92-11.14 5.88-4.06-.42-7.71 1.53-11.61 2.14-2.91.45-5.72 1.63-8.7 2.05-1.05.15-2.06.6-2.57 1.69-3.64.26-1.94-2.53-2.35-4.12-.7-1.92-.16-3.02 1.9-3.69 5.33-1.73 10.62-3.6 16.29-3.95 5.41-.86 10.84-1.61 16.23-2.62 2.43-.46 4.83-.15 7.23-.32-1.33 1.76-4.23.68-5.28 2.94z"
    ></path>
    <path
      fill="#7a93fc"
      d="M73.03 94.52c-3.99 1.72-8.38 2.02-12.45 3.45-1.45.51-2.94.9-4.41 1.32-1.56.45-1.26 1.73-1.31 2.86-1.06-1.04-.41-2.35-.57-3.53 3.69-1.55 7.36-3.15 11.41-3.57 1.46-.15 1.98-1.78 3.23-2.3 2.22-1.07 4.64-1.37 7.04-1.76 2.62-1.23 5.39-1.33 8.21-1.17.99.14 2.11-.39 2.93.59-3.35.59-6.69 1.26-10.05 1.72-1.74.24-3.14.82-4.02 2.39z"
    ></path>
    <path
      fill="#a98efd"
      d="M21.42 99.81c-1.92-.81-2.84 1.07-3.91 1.83-2.15 1.54-4.46 2.68-6.8 3.8-1.18.57-1.7 1.53-2.19 2.6-1.02-.09-1.53.84-2.34 1.17.19-.98.39-1.96.58-2.94.98-1.37 1.96-2.74 2.93-4.11.26-.55.65-.96 1.24-1.16 3.46-.84 5.93-3.58 9.21-4.76.87-.31 1.63-1.14 2.66-.45.7 1.74-.45 2.84-1.38 4.02z"
    ></path>
    <path
      fill="#ba89ee"
      d="M10.86 101.57c-.39.2-.78.39-1.17.59-.63-1.31.62-2.31.58-3.53.6-1.59 2.12-2.37 3.14-3.59 1.88-1.03 3.4-2.79 5.73-2.94.58.82.17 1.66.06 2.49-.16.7-.4 1.37-.8 1.98-2.26 2.05-4.75 3.74-7.54 5z"
    ></path>
    <path
      fill="#c08aea"
      d="M13.79 95.69c-1.17.98-2.35 1.96-3.52 2.94-2.08.4-3.38 2.21-5.28 2.93-.85-.09-1.62.19-2.35.58-1.07-2.91 1.84-2.8 3.01-4.01 1.93-.79 3.73-1.84 5.54-2.87q2.09-1.2 2.6.42z"
    ></path>
    <path
      fill="#839dfd"
      d="M19.07 112.15c2.01-1.6 5.6-.7 6.45-4.11.91-.68 1.72-1.6 3.05-1.21.89 1.45-.24 2.46-.78 3.6-.37.61-.79 1.19-1.37 1.63-3.97 2.35-8.29 4.19-11.46 7.73-.81.09-1.44.89-2.34.59-.36-1.43 1.16-2.22 1.17-3.53 1.76-1.57 3.52-3.13 5.28-4.7z"
    ></path>
    <path
      fill="#fa4b73"
      d="M.39 66.34v-2.38c.19-.19.38-.37.59-.54 2.5-1.89 4.04-4.9 7.13-6.1.46.1.71.41.87.82.3 1.31-.27 2.5-.49 3.74-.33 1.05-.14 2.24-.84 3.19-1.13 1.22-2.65 1.85-4.03 2.68C.9 69.39.89 69.37.4 66.33z"
    ></path>
    <path
      fill="#cb88e0"
      d="M6.17 98.63c-1.08 1.27-3.62 1.07-3.53 3.52-.58.2-1.17.4-1.75.6-.25-1.75.54-3.6-.52-5.26 0-.41-.01-.82-.02-1.24.18-.17.39-.26.64-.29 1.5-.97 2.6-2.64 4.63-2.71.48.55.3 1.19.24 1.81-.04.53.28.8.73.97.23.12.38.31.47.55.17.89-.53 1.4-.89 2.06z"
    ></path>
    <path
      fill="#f85680"
      d="M.39 66.34c.55 1.27.79 2.85 2.65.89 1.13-1.18 2.85-1.8 4.3-2.67.73-.41 1.32-1.29 2.35-.74.19.23.29.49.32.79-1.11 2.11-3.44 3.27-4.27 5.59-.28.27-.61.48-.93.69-1.18.78-2.25 2.4-3.7 1.82-1.25-.49-.08-2.33-.72-3.42v-2.94z"
    ></path>
    <path
      fill="#ee6496"
      d="M.38 79.26v-2.4c1.17-1.94 3.49-2.67 4.74-4.53 2.76-.68 2.86-.54 2.26 3.08-.39 1.51-1.96 1.83-2.85 2.83-.54.88-1.13 1.68-2.16 2.11-1.2.5-1.73.01-1.98-1.1z"
    ></path>
    <path
      fill="#ee70a5"
      d="M.38 79.26c1.84 1.61 2.64.2 3.45-1.2.9-.21 1.82-.69 2.62.24.28 3.65-2.4 5.26-4.83 7.05-.45.14-.86.1-1.22-.24 0-1.95-.01-3.91-.02-5.86z"
    ></path>
    <path
      fill="#f7608e"
      d="M5 72.78c-.54 2.49-2.93 2.89-4.61 4.09v-7.58c.96.83.15 2.09.72 3.02 1.84-.19 2.64-2.27 4.47-2.46.49.43.52.99.41 1.58-.16.58-.62.94-.99 1.36z"
    ></path>
    <path
      fill="#ea80bf"
      d="M.39 85.12h1.09c.35.36.44.8.42 1.28-.33 1.65-.06 3.37-.49 5.02-.21.44-.46.81-1.04.72 0-2.34.01-4.68.02-7.01z"
    ></path>
    <path
      fill="#de84cd"
      d="M.37 92.14l.52-.55c.4-.35.82-.33 1.25-.06.41 1.8-.42 3.27-1.25 4.75-.18 0-.36 0-.54-.02 0-1.37.01-2.74.02-4.12z"
    ></path>
    <path
      fill="#fc5573"
      d="M.89 63.96h-.5l-.18-6.49.19.1c2.01.82 1 2.62 1.31 4 0 .89-.21 1.71-.82 2.4z"
    ></path>
    <path
      fill="#7c9dfb"
      d="M12.62 120.38c.75-.32 1.33-1.34 2.34-.59v.59c-2.14.6-3.75 2.01-5.28 3.53-.66-2.54 2.01-2.31 2.93-3.53z"
    ></path>
    <path
      fill="#619bfb"
      d="M44.29 118.03c0 1.18 0 2.35.01 3.53 0 .19 0 .39-.01.58-2.11-.39-3.66 2.01-5.86 1.18.89-3.49 3.14-5.52 5.86-5.29z"
    ></path>
    <path
      fill="#fc3147"
      d="M20.25 12.25c.47.26.57.7.58 1.18-.08 1.36.33 2.96-1.98 2.94-.7 0-.34.96-.35 1.47-.05 1.47-.02 2.94-.02 4.41-1.66 1.08-2.61 3.07-4.66 3.65-.39-1.4-1.72-1.08-2.67-1.47-.58-1.83-1.11-3.65.4-5.31.86-.72.83-1.99 1.65-2.74.45-.35.93-.68 1.35-1.07 1.63-1.53 2.86-3.79 5.7-3.05z"
    ></path>
    <path
      fill="#fb1a51"
      d="M19.07 26.94c.86-.49 1.92-.7 2.35-1.76.92-.72 1.89-1.36 2.7-2.2.61-.63 1.34-1.28 2.29-.89.96.39.96 1.37.99 2.25.02.59-.07 1.16-.3 1.71-1.5 1.94-3.14 3.6-5.91 3.37-.96-.08-1.15.88-1.58 1.48-1.12 1.54-1.44.47-1.79-.54-.13-.92-.14-1.84.16-2.73.22-.44.5-.8 1.08-.67z"
    ></path>
    <path
      fill="#fd384f"
      d="M13.79 25.77c1.55-1.19 2.22-3.56 4.69-3.53.19 1.17.39 2.35.58 3.52-1.6 1.49-3.4 2.71-5.2 3.94-.77.39-1.55.79-2.43.34-.32-.34-.4-.75-.35-1.2.48-1.39 1.15-2.62 2.71-3.07z"
    ></path>
    <path
      fill="#fd1f4b"
      d="M21.42 24.59c0-.48.16-1.05-.03-1.44-1.88-3.85 1.2-4.81 3.55-6.2 2.28-.42 1.67 1.31 1.83 2.46-1.1 2.42-2.56 4.49-5.35 5.17z"
    ></path>
    <path
      fill="#fb0d4f"
      d="M21.42 24.59c1.8-1.33 3.36-2.9 4.7-4.7 1.94-1.5 1.05-4.13 2.34-5.88h.58c1.21 1.59.83 3.37.63 5.13-1.85 1.93-1.53 4.49-1.98 6.83-.14.25-.34.43-.61.53-.13-.05-.26-.1-.39-.16 0-.58.06-1.17-.01-1.75-.09-.71.42-1.74-.56-2.04-.85-.26-1.4.64-1.78 1.23-.73 1.12-1.65 1.56-2.92 1.38v-.59z"
    ></path>
    <path
      fill="#fc154b"
      d="M28.46 14.01c-.38 2.12 1.23 5.04-2.34 5.88-.26-1.03.71-2.56-1.18-2.94 1.13-.93.47-2.46 1.17-3.53h.58c.58.23 1.63-.98 1.77.59z"
    ></path>
    <path
      fill="#fc2d4a"
      d="M13.79 29.29c1.59-1.43 2.98-3.16 5.27-3.53v1.18l-.59.59c-.7 1.28-1.44 2.56-2.94 3.03-1.04.32-2.21.6-1.75-1.27z"
    ></path>
    <path
      fill="#fd3a46"
      d="M13.21 16.36c-.25 1.03.71 2.56-1.17 2.94-.76.22-1.22-.17-1.57-.77-.4-.99-.18-1.9.4-2.75.59-.78 1.17-1.57 1.76-2.35.98.82.44 1.95.59 2.94z"
    ></path>
    <path
      fill="#f50155"
      d="M36.66 21.06c.7 1.49 1.42 2.76-.6 4.1-1.25.82-.4 2.71-.57 4.11-1.22.98-2.65 1.64-3.88 2.61-.76.6-1.68 1.06-2.69.4-.65-1.29.09-2.61-.1-3.91-.35-1.96.61-3.1 2.31-3.88 1.59-.73 3.2-1.45 4.37-2.84.1-.42.19-.85.29-1.27.29.23.59.45.88.68z"
    ></path>
    <path
      fill="#f80350"
      d="M29.04 19.89v-5.88c3.34.73 5.8 3.93 5.87 7.63-.43.96-1.27 1.47-2.18 1.78-1.12.39-2.27 1.54-3.47.43-1.27-1.17-1.01-2.59-.21-3.96z"
    ></path>
    <path
      fill="#fb1953"
      d="M29.04 19.89v.29q.03 4 3.58 2.55c.78-.32 1.33-1.12 2.28-1.09.19 0 .39.01.58.02-.05 2.7-2.62 2.5-4.12 3.47-1.19.77-2.47 1.21-1.75 2.99-1.48.35-3.17.98-2.34-1.76-.02-1.58.03-3.17-.09-4.75-.1-1.45 1.26-1.12 1.84-1.71z"
    ></path>
    <path
      fill="#d163b3"
      d="M54.28 61.62c0 .78-.02 1.55-.03 2.33-.15.26-.29.28-.44 0 .05-2.55-.64-5.15.48-7.63.8-.08 1.71.27 2.32-.59.91-.56 1.9-.65 2.93-.59.82 1.04.64 2.03-.17 2.97-.21.2-.46.35-.73.47-1.92.33-3.44 1.25-4.37 3.03z"
    ></path>
    <path
      fill="#cd69c0"
      d="M53.81 63.95h.44c0 .78.01 1.57.02 2.35-.15.19-.3.38-.46.56v-2.92z"
    ></path>
    <path
      fill="#d566bf"
      d="M58.95 58.09c.56-.91.65-1.91.59-2.94 1.95-.78 3.91-1.57 5.86-2.35-.29.78-1.07 1.57 0 2.35l-1.76 1.76c-1.37 1.18-2.76 2.25-4.69 1.18z"
    ></path>
    <path
      fill="#f45da0"
      d="M44.78 50.45h-.49c-.1-.1-.2-.2-.31-.3-.41-.82-.41-1.67-.19-2.53.11-.27.28-.5.51-.68h.47v3.52z"
    ></path>
    <path
      fill="#fd4b45"
      d="M.38 18.75c0-2.75 0-5.49-.01-8.24 1.25 2.24.02 4.73.78 7.32 2.05-1.64 3.33-1.37 3.26 1.47 1.33 1.62 1.17 3.52 1.04 5.42-.96 2.16-1.7 4.56-4.79 4.38-.08-.64-.04-1.27-.05-1.91-.14-2.81.28-5.63-.23-8.43z"
    ></path>
    <path
      fill="#fd4142"
      d="M9.1 6.37c.39.78.78 1.57 1.17 2.35 0 .87-.11 1.76.02 2.6.53 3.37-1.08 5.25-4.12 6.21-.94-2.15.89-4.31 0-6.46.33-1.98 1.49-3.43 2.93-4.7z"
    ></path>
    <path
      fill="#fd4144"
      d="M5 24.59c-.2-1.76-.39-3.53-.59-5.29.59-.39 1.17-.78 1.76-1.17.49.73.69 2.48 1.88.41.66-1.15 1.64-2.08 2.81-2.76v2.94c-.92 2.28-3.33 3.41-4.31 5.65-.49.29-.98.55-1.56.23z"
    ></path>
    <path
      fill="#fc4237"
      d="M9.1 6.37c-.5 1.87-1.78 3.25-2.93 4.7-1.97-4.7-1.87-5 2.93-8.82v4.11z"
    ></path>
    <path
      fill="#f72261"
      d="M29.04 32.23c1.29-.12 2.35-.75 3.16-1.71.89-1.05 2-1.35 3.29-1.24v2.37c.01.5-.23.87-.58 1.2-.02.64.1 1.33-.59 1.74-1.9-.06-3.15 2.38-5.29 1.39-.87-.93-.43-2.01-.33-3.05.05-.26.16-.5.34-.69z"
    ></path>
    <path
      fill="#ed135a"
      d="M34.91 32.84c0-.49.11-.94.58-1.2.47.01.91.1 1.16.57-.58.21-1.16.41-1.74.62z"
    ></path>
    <path
      fill="#ed467f"
      d="M34.32 45.15c2.15-1.17 4.3-2.35 6.45-3.52.07 1.03-.03 2.03-.58 2.94 1.13 1.9-.41 2.76-1.47 3.81-1.87.67-3.26 2.54-5.52 2.26-.35-.29-.5-.68-.53-1.12.31-1.55-.15-3.39 1.66-4.37z"
    ></path>
    <path
      fill="#e84a89"
      d="M38.43 48.09c.59-1.18 2.09-1.89 1.76-3.52 1.56-.13 2.47-1.8 4.1-1.76 0 1.38.01 2.75.02 4.13 0 .19-.01.39-.02.58-1.43 2.02-2.92 3.96-5.81 3.69-1.01-1.02-.56-2.06-.06-3.11z"
    ></path>
    <path
      fill="#fd4951"
      d="M.39 39.31v-.66c.48-1.84.07-3.71.24-5.56 1.07-1.66 2.47-2.73 4.56-2.57.31.39.46.84.54 1.33.12 1.76.19 3.52-.09 5.28-.9 1.9-2.36 3.11-4.4 3.61-.78-.18-.49-.99-.84-1.43z"
    ></path>
    <path
      fill="#fd495d"
      d="M.21 45.18c.06-.2.13-.41.19-.61.19-.21.37-.43.55-.66 1.08-1.11 2.38-1.94 3.67-2.76 1.29-.82 1.98-.27 1.94 1.23-1.14 1.56-2.37 3.09-2.15 5.22-.3 1.22-.7 2.4-1.48 3.42-1.52 1.41-2.3 1.21-2.33-.99-.03-1.62.25-3.26-.19-4.87l-.19.02z"
    ></path>
    <path
      fill="#fc5558"
      d="M.9 32.81c-.23 1.94.51 3.97-.5 5.84V27.53c.17-.26.33-.28.5 0v1.76c1.09 1.17.68 2.35 0 3.52z"
    ></path>
    <path
      fill="#fc554e"
      d="M.89 27.53h-.5c0-2.93 0-5.85-.01-8.78 1.11 2.87.23 5.86.51 8.78z"
    ></path>
    <path
      fill="#fc5558"
      d="M.9 44.57H.4v-5.26c.17.38.33.77.5 1.15 1.11 1.37.82 2.74 0 4.11z"
    ></path>
    <path
      fill="#fc4561"
      d="M2.65 50.45l1.17-2.94c.46-.41.87-.91 1.6-.7 1.65 2.96.33 5.61-.92 8.23-.45.94-1.49 1.57-1.56 2.75-.49 1.38.05 3.31-2.05 3.82-.22-1.34.49-2.8-.5-4.05v-.69c.46-2.24 1.7-4.21 2.26-6.42z"
    ></path>
    <path
      fill="#fc5267"
      d="M2.65 50.45c.57 2.61-.67 4.58-2.26 6.42V45.16c.99 1.51.39 3.19.47 4.79.11 2.22.92 1.29 1.78.5z"
    ></path>
    <path
      fill="#f2477e"
      d="M34.32 45.15c-.39 1.37-.78 2.74-1.17 4.12-3.1 2.27-6.29 4.4-9.75 6.1-.89.34-1.72.85-2.63 1.11-.99.28-1.97.42-1.96-1.12 1.72-2.13 4.26-3.41 5.86-5.67 1.6-1 2.61-2.52 3.58-4.09.75-.59 1.54-1.1 2.56-1.03.64-.22 1.31-.36 1.9-.67 1.51-.79 1.63.12 1.62 1.25z"
    ></path>
    <path
      fill="#f33f7b"
      d="M30.8 44.57c-.59.76-1.41 1.08-2.34 1.18-.32-.39-.36-.78 0-1.18 4.1-3.06 8.25-6.04 12.9-8.23.1 1.84-.4 2.83-2.25 4.05-2.66 1.75-6.26 1.45-8.31 4.18z"
    ></path>
    <path
      fill="#a581fc"
      d="M54.28 83.33c0-1.37.01-2.73.02-4.1.19-.18.38-.35.6-.5.82-.41 1.71-.62 2.55-.98.24-.11.49-.2.74-.28 1.73-.63 3.71-.65 5.08-2.14.51-.49.9-1.09 1.36-1.62 2.41-1.51 4.99-2.15 7.81-1.52-.59.98-1.17 1.96-1.76 2.94-1.38 1.56-2.18 3.63-4.11 4.7-4.4.13-7.93 3.28-12.3 3.51z"
    ></path>
    <path
      fill="#ae79f1"
      d="M72.44 72.19c-2.44 1.1-5.52.15-7.63 2.35a40.115 40.115 0 01-7.78 2.93c-.9.24-1.82.74-2.74.02 0-.4 0-.79-.01-1.19.46-.7.82-1.49 1.65-1.88 2.98-1.63 5.99-3.19 9.37-3.84 1.1-.21 1.96-.8 2.57-1.75.51-.58 1.12-1 1.85-1.25 1.49-.18 2.97-.95 4.49-.1.73.05 1.83-.27 1.17 1.18-.33 1.34-1.87 1.71-2.34 2.94l-.59.59z"
    ></path>
    <path
      fill="#ba71dc"
      d="M74.2 67.49c-1.53.47-3.08.72-4.69.6-1.07.13-.69-.57-.57-1.06.16-.68.97-.98 1.05-1.73.38-.46.79-.64 1.29-.16 2.45-.99 5.05-1.3 7.62-1.76-.77 2.29-2.54 3.42-4.7 4.11z"
    ></path>
    <path
      fill="#cd69c0"
      d="M54.28 61.62c-.25-3.05 2.02-3.18 4.09-3.53.49.49.43 1.16.59 1.76-.84 1.03-2.85.59-3.07 2.59-.04.39-.78-1.07-1.6-.81z"
    ></path>
    <path
      fill="#ca66bf"
      d="M58.95 59.85c-1.55-.13-.37-1.18-.59-1.76h.59c1.56-.39 3.13-.78 4.69-1.18.69.89-.6 1.1-.59 1.76-1.37.39-2.74.79-4.1 1.18z"
    ></path>
    <path
      fill="#c06ed3"
      d="M71.27 65.14c-.48.01-.92.11-1.17.59-3.04 2.06-6.74 2.3-10.04 3.64-.81.33-1.74.29-2.27-.7.78-1.37 1.56-2.75 2.34-4.12 3.49-1.12 6.89-2.57 10.64-2.7.86-.03 1 .32 1.09.95-.2.78-.39 1.56-.59 2.35z"
    ></path>
    <path
      fill="#f44074"
      d="M28.45 44.57v1.18c-.45 2-1.37 3.58-3.52 4.11-1.9 1.33-3.19 3.66-5.91 3.68-.65-.67-.73-1.54-.86-2.39 0-.29.06-.56.18-.82 2.09-2.01 4.59-3.48 6.94-5.16.85-.24 1.49-1.4 2.58-.6h.58z"
    ></path>
    <path
      fill="#f22763"
      d="M29.04 35.75c1.94.43 3.24-2.06 5.28-1.18-1.96 1.37-3.91 2.75-5.87 4.12-.72 1.1-1.39 2.28-3.05 1.81-.41-.47-.49-1.04-.45-1.63.04-.28.11-.55.22-.81.76-1.64 2.46-1.72 3.86-2.31z"
    ></path>
    <path
      fill="#f63970"
      d="M25.53 40.45c.98-.58 1.95-1.16 2.93-1.75.36 2.01-.67 3.88-.58 5.87-.78.39-1.57.78-2.35 1.17-.84-.81-.74-1.88-.8-2.91.04-.87.1-1.74.81-2.39z"
    ></path>
    <path
      fill="#679dfc"
      d="M44.29 118.03c-3.16.42-3.98 3.44-5.86 5.29-3.62.22-6.83 1.83-10.19 3.08.9-1.39 2.05-2.55 3.6-2.93 3.04-.75 4.82-2.73 6-5.44 1.62-2.41 2.97-5.15 6.45-5.29v5.29z"
    ></path>
    <path
      fill="#b977e7"
      d="M57.78 68.67c3.37.43 6.09-1.82 9.25-2.27 1.03-.14 2.04-.45 3.06-.68.47.95-1.63 1.27-.58 2.36-.39.39-.78.78-1.18 1.17-2.97 1.48-6.3 1.94-9.33 3.32-1.31.59-2.4 1.22-2.99 2.56l-1.74 1.17c0-.69.04-1.38-.03-2.06-.21-2.2-.11-4.16 2.75-4.47.57-.06.71-.6.78-1.1z"
    ></path>
    <path
      fill="#b27ff7"
      d="M54.29 77.49c3.6-.66 6.8-2.74 10.52-2.95-.22.56-.61.95-1.17 1.18-1.74 1.34-4.06 1.21-5.87 2.35-.97.4-2.14.32-2.93 1.17h-.56c-.01-.39-.03-.78-.04-1.17 0-.19.02-.38.04-.57z"
    ></path>
    <path
      fill="#729bfd"
      d="M44.29 109.21v1.76c-3.71 1.57-7.43 3.13-11.14 4.7-.23-1.72 1.34-2.67 1.76-4.11 2.94-1.52 5.78-3.47 9.38-2.35z"
    ></path>
    <path
      fill="#7b9afd"
      d="M26.11 111.56c.39-.39.78-.78 1.17-1.18 4.75-3.56 10.09-5.98 15.66-7.91.48-.16.95-.09 1.35.27v2.35c-.42.69-.99 1.32-1.82 1.3-2.12-.05-3.9 1.01-5.8 1.64-2.05-.12-3.59.61-4.3 2.65-1.24 3.53-4.22 4.42-7.43 4.99v-1.76c-.12-1.04.77-1.57 1.17-2.35z"
    ></path>
    <path
      fill="#7699fd"
      d="M36.67 108.04c1.2-1.46 2.64-2.38 4.64-2.14 1.08.13 2.03-.35 2.98-.8v4.12c-3.31.03-6.11 2.15-9.38 2.35.07-1.44 1.1-2.38 1.76-3.53z"
    ></path>
    <path
      fill="#7c9dfb"
      d="M26.11 111.56c-.1.93-.41 1.75-1.17 2.35-2.83 1.06-5.11 2.98-7.41 4.84-.79.63-1.51 1.16-2.56 1.04 1.57-4.24 5.97-4.89 9.09-7.17.61-.45 1.37-.71 2.06-1.06z"
    ></path>
    <path
      fill="#8993fc"
      d="M28.46 106.86c-.92.53-1.7 1.42-2.93 1.18.78-1.76 1.56-3.53 2.35-5.29 0-.9.47-1.53 1.34-1.49 1.58.07 2.3-1.1 3.24-1.98 1.7-1.14 3.42-2.26 5.28-3.11.9-.41 1.99-.83 2.75-.04.99 1.03-.39 1.64-.76 2.43-.17.35-.39.67-.53 1.04-.45 1.11-1.09 2.21-2.17 2.65-3.02 1.24-5.95 2.6-8.56 4.61z"
    ></path>
    <path
      fill="#8e96fd"
      d="M32.56 99.81c-.51 2.68-3.4 1.52-4.69 2.94-2.34.57-4.31 1.94-6.45 2.94.64-4.65 4.46-5.47 7.98-6.63.88-.29 1.98.01 2.58-1.01.2-.2.44-.34.73-.39 2.74.9-.41 1.41-.14 2.15z"
    ></path>
    <path
      fill="#9c8bfd"
      d="M32.56 98.04h-.59c-2 .02 0-1.96-1.17-2.35.38-1.86 1.63-3.23 2.66-4.73 2.75-2.07 6.15-2.57 9.23-3.85.55-.23 1.13-.08 1.6.35v2.35c-.27 1.73-.98 3.04-2.9 3.38-2.81.5-5.35 1.53-7.05 4-.43.62-1.27.37-1.77.83z"
    ></path>
    <path
      fill="#a68bfd"
      d="M33.73 91.58c-1.06 1.31-1.32 3.19-2.93 4.11-2.74 1.37-5.47 2.74-8.21 4.12h-1.17c-.35-1.58 1.52-2.53 1.17-4.11 1.13-1.01 2.53-1.54 3.9-2.11 1.48-.19 2.65-1.13 3.99-1.66 1.02-.4 2.07-1.2 3.25-.35z"
    ></path>
    <path
      fill="#9290fd"
      d="M32.56 98.04c2.33-3.33 5.45-5.39 9.53-5.82 1.53-.16 1.37-1.7 2.2-2.41v7.64c-1.58 1.84-3.53 2.46-5.87 1.75.32-1.11 1.31-1.77 2.37-2.88-3.64-.03-5.58 2.56-8.23 3.47-.57-.59 1.65-1.18 0-1.76z"
    ></path>
    <path
      fill="#e15aa2"
      d="M44.3 50.45v7.05c-.96.84-1.93 1.67-3.34 1.47-.76-.39-1.31-.97-1.51-1.83.12-.53.54-.88.79-1.33.39-1.3.58-2.68 1.29-3.87.61-1.03 1.34-1.96 2.78-1.49z"
    ></path>
    <path
      fill="#dd5ca8"
      d="M40.77 58.67c1.3 0 2.09-1.53 3.51-1.18v1.77c-.6 1.1-1.55 1.66-2.75 1.87-1.42.25-1.97-.38-1.79-1.77.18-.47.44-.83 1.02-.7z"
    ></path>
    <path
      fill="#8096fc"
      d="M38.43 99.22c1.96-.58 3.91-1.17 5.87-1.75v5.28c-4.82 1.89-9.77 3.47-14.03 6.59-.81.6-1.97.71-2.97 1.05.01-1.3 1.53-2.1 1.17-3.53 1.34-2.78 3.97-3.66 6.62-4.47 1.66-.51 3.02-1.28 3.35-3.17z"
    ></path>
    <path
      fill="#be78e0"
      d="M44.31 76.9c0 .19-.01.39-.02.58-1.25 2.58-3.95 2.7-6.16 3.65-1.7.96-3.46 1.79-5.23 2.62-.91.43-1.96 1.15-2.84-.13.05-1.04.63-1.91.87-2.89 1.68-2.55 4.7-2.72 7.11-3.95 1.18-.6 2.57-.75 3.71-1.48.82-.34 1.64-.88 2.53-.18 0 .59.01 1.18.02 1.77z"
    ></path>
    <path
      fill="#c574d8"
      d="M44.29 75.13c-.78.2-1.56.39-2.34.59-2.09-1.03-.57-2.62-.62-3.97.58-1.32 1.13-2.66 2.96-2.49v5.87z"
    ></path>
    <path
      fill="#d468b8"
      d="M44.3 65.73c0 .19-.01.38-.02.58-1.93 2.11-4.96 2.04-7.13 3.66-.55.2-1.02.57-1.55.81-2.2 1-2.43.84-2.67-1.76 1.8-1.91 5.02-2.25 5.83-5.28 1.66-1.2 3.42-2.13 5.54-2.12v4.12z"
    ></path>
    <path
      fill="#dd65ad"
      d="M44.29 61.61c-1.52 1.32-3.55 1.49-5.27 2.35-1.36.75-2.7 1.44-3.51-.69 0-3.46 1.45-4.71 4.68-4.02.04.75-.17 1.75 1.17 1.22.99-.39 1.96-.81 2.93-1.22v2.35z"
    ></path>
    <path
      fill="#a785fd"
      d="M33.73 91.58c-2.63-.07-4.29 2.79-7.04 2.35-.2-.19-.32-.41-.34-.69.14-.95.77-1.7 1.07-2.59.25-.51.59-.95 1.06-1.28 3.17-1.5 5.98-3.87 9.69-4.11.65-.09.64 1.48 1.84.49 1.12-.93 2.58-1.79 4.27-1.22v2.94c-3.59 1.2-7.29 2.08-10.56 4.11z"
    ></path>
    <path
      fill="#ae81fa"
      d="M44.29 84.53c-1.46.63-3.01 1.12-4.36 1.94-1.18.72-1.1-.35-1.5-.77 1.94-3.71 3.08-4.51 5.86-4.12v2.94z"
    ></path>
    <path
      fill="#b87fee"
      d="M44.29 81.59c-3.05-.19-3.64 3.13-5.86 4.12-3.7.47-6.46 3.19-9.97 4.11-.68-.89-.3-1.84-.15-2.78.92-1.12 2.33-1.33 3.57-1.68 2.77-.79 4.73-2.38 5.97-4.94 2.27-.72 4.57-1.36 6.45-2.94v4.11z"
    ></path>
    <path
      fill="#cb73cd"
      d="M36.67 69.25c2.54-.98 5.08-1.96 7.61-2.95v2.95c-1 .57-1.78 1.35-2.34 2.35-3.56 2.34-6.74 5.44-11.28 5.9-.39-.51-.43-1.09-.33-1.7.34-1.49 1.05-2.72 2.58-3.18 1.8-.54 2.84-1.87 3.75-3.37z"
    ></path>
    <path
      fill="#b08afa"
      d="M26.7 93.34v.59c-1.28.79-2.41 1.93-4.1 1.77-2.83 1.61-5.65 3.23-8.49 4.82-1 .56-2.01 1.18-3.24 1.06 2.35-1.76 4.69-3.53 7.04-5.29 2.04-1.56 3.68-3.73 6.41-4.25-.18 2.21 1.92.27 2.39 1.31z"
    ></path>
    <path
      fill="#c084e7"
      d="M13.79 95.69c-.78-1.88-1.56.37-2.34 0-.69-1.71.93-2.76 1.19-4.2.11-.26.25-.5.43-.73 2.56-1.73 4.89-3.84 7.95-4.73 1.43.91.51 2.12.42 3.23-.61 1.11-.55 2.78-2.37 2.9-1.76 1.17-3.52 2.35-5.28 3.52z"
    ></path>
    <path
      fill="#ba82e8"
      d="M19.07 92.17c.94-.77 1.17-1.96 1.76-2.94 1.63-1.47 3.18-3.06 5.55-3.24.76.63.87 1.54 1.05 2.42-2.32 3.01-5.1 5.34-8.95 6.1.19-.78.39-1.57.58-2.35z"
    ></path>
    <path
      fill="#b285f7"
      d="M18.49 94.52c3.38-1.54 6.21-3.83 8.8-6.46.23-.53.54-.88 1.17-.59 1.05.78-.21 1.57 0 2.35-.2.39-.39.78-.59 1.18-1.01.86-1.92 2.06-3.52 1.17-2.17 1.33-3.89 3.39-6.45 4.11.2-.59.39-1.18.59-1.76z"
    ></path>
    <path
      fill="#c37fda"
      d="M13.21 91.58c.06 1.65-1.47 2.61-1.76 4.11-1.57 1.32-3.38 2.21-5.28 2.93.19-.78.39-1.56.58-2.35 1.24-2.83 3.61-4.09 6.45-4.7z"
    ></path>
    <path
      fill="#fc3e66"
      d="M.9 61.61c.98-1.2 1.2-2.62 1.17-4.12 1.58-2.56 4.63-3.53 6.33-6.11.79-1.2 1.08.52 1.33 1.21-.38 1.72.05 3.73-1.8 4.9-2.35 2.15-4.15 4.9-7.04 6.46V61.6z"
    ></path>
    <path
      fill="#f83561"
      d="M7.93 57.5l1.17-5.29c.72-.95-.52-2.27.6-3.15.4-.39.87-.66 1.36-.92 1.47-.62 2.62-1.62 3.53-2.92.76-.7 1.3-1.82 2.65-1.44.77.79.44 1.76.46 2.68-.53 1.66-1.83 2.67-3.17 3.61-.7.49-1.56.79-1.59 1.85-1.23 1.8-.95 4.44-3.13 5.72-.41.24-.72.66-1.28.43-.2-.19-.4-.38-.59-.57z"
    ></path>
    <path
      fill="#f9426e"
      d="M8.52 58.07c2.41-1.48 2.24-4.61 4.1-6.45.33-.66.98-.89 1.57-1.25 2.89-1.75 2.96-1.73 3.22 1.7-.08.76-.35 1.44-.86 2.01-.98.96-2.06 1.79-3.3 2.41-1.09.55-1.98 1.27-2.06 2.63-.43 1.72-.86 3.45-3.26 3.08.2-1.37.39-2.75.59-4.12z"
    ></path>
    <path
      fill="#f74b79"
      d="M7.93 62.2c1.86-.44 2.39-1.98 2.93-3.52.89-1.54 2.28-2.33 3.96-2.7 1.6.4 1.01 1.93 1.49 2.9-1.5 2.62-4.23 3.64-6.63 5.08-.78.2-1.56.39-2.34.59.72-.65-.4-1.77.59-2.36z"
    ></path>
    <path
      fill="#d77dc6"
      d="M.9 96.27c-.14-1.7.54-3.19 1.17-4.7-.42-.96.17-1.56.77-2.15 1.13-.7 2.3-1.35 3.35-2.19.28-.08.55-.08.83-.02.61.28.78.8.8 1.41-.07.87-.31 1.72-.41 2.59-.35.93-.35 2.18-1.83 2.15-1.51 1.06-2.84 2.39-4.68 2.91z"
    ></path>
    <path
      fill="#c983de"
      d="M13.21 91.58c-2.16 1.55-4.58 2.75-6.45 4.7h-.58c-.33-.33-.38-.73-.28-1.16 1.09-1.23 1.77-2.77 2.95-3.93 1.43-.91 2.61-2.33 4.53-2.24 1.23.78.67 1.42-.17 2.03v.59z"
    ></path>
    <path
      fill="#cd7fcf"
      d="M9.1 91.58c-1.02 1.14-1.13 3.04-2.93 3.54-.39.34-.78.34-1.17 0 .19-.59.38-1.17.57-1.76.76-.6 1.08-1.43 1.18-2.36.53-.83 1.18-1.65 2.25-1.12.72.35.51 1.1.1 1.7z"
    ></path>
    <path
      fill="#c37fda"
      d="M5 95.12h1.17v1.17c-1.58.81-.99-.56-1.17-1.17z"
    ></path>
    <path
      fill="#f15889"
      d="M5.59 71.6v-1.76c0-2.84 2.56-3.66 4.11-5.29 1.3-1.28 2.38-2.94 4.58-2.62.68.37.29 1.87 1.86 1.46 1.15-.3 1.1.92 1.08 1.77-.13.57-.44 1.03-.88 1.42-.71.52-1.5.81-2.38.85-2.12-.15-3.51 1.21-5.03 2.36-1.01.76-1.76 2.08-3.34 1.81z"
    ></path>
    <path
      fill="#f45082"
      d="M14.38 62.2c-1.96-.01-2.93 1.97-4.69 2.35v-.59c1.52-2.54 4.72-3.01 6.45-5.29 1.33-.88.81-3.3 2.93-3.53.08.84.43 1.24 1.31.8.93-.47 1.87-.93 2.8-1.39.45.48.54 1.03.35 1.65-2.6 2.69-5.88 4.33-9.15 5.99z"
    ></path>
    <path
      fill="#ef5d8e"
      d="M5.59 71.6c1.58-1.27 3.1-2.64 4.78-3.77.97-.65 2.01-1.68 3.43-.93-.12 2.29-1 4.3-2.2 6.2-1.71.69-2.58 2.95-4.84 2.61 0-.47-.11-1 .05-1.41.95-2.4-.52-1.85-1.81-1.53 0-.48.11-.92.59-1.18z"
    ></path>
    <path
      fill="#ea6aa0"
      d="M6.76 75.71c1.79-.61 2.66-2.71 4.69-2.94.52.46.59 1.05.51 1.7-1.21 2.35-3.19 3.47-5.79 3.6-.78.54-1.56.54-2.34 0 .51-1.37 2.2-1.26 2.93-2.35z"
    ></path>
    <path
      fill="#e56daa"
      d="M6.17 78.07c1.65-1.34 4.04-1.57 5.28-3.53.6-.81 1.03-1.97 2.42-1.25.43 1.64-.02 3.15-.71 4.62-.21.36-.48.67-.79.94-1.08.76-1.7 1.89-2.42 2.96-.25.32-.54.59-.88.82-2.64 1-4.73 3.12-7.59 3.66v-1.17c1.73-2.24 4.65-3.68 4.69-7.05z"
    ></path>
    <path
      fill="#e076bb"
      d="M1.48 86.29C3.77 84.46 6.27 83 9.1 82.18c.34 1.17 1.52.05 2.03.75.3.51.32 1.05.19 1.61-.91 1.94-2.77 2.4-4.57 2.94h-.59c-.84.96-2.24 1.21-2.94 2.35-.39.59-.78 1.17-1.17 1.76-.39 0-.78 0-1.17.01.26-1.76-.59-3.64.59-5.3z"
    ></path>
    <path
      fill="#fd3e49"
      d="M5 31.64v-1.17c.84-2.07 2.13-4.06 1.18-6.47.87-2.38 2.8-3.82 4.69-5.29.39.2.78.39 1.17.59-1.47 1.62-.24 3.54-.59 5.29.88.94.75 1.98.33 3.06-1.24 1.91-2.14 4.17-4.69 4.8-.62-.45-1.44-.4-2.09-.8z"
    ></path>
    <path
      fill="#fc3748"
      d="M11.45 28.11v-3.53c.6-.51 1.2-1.03 1.82-1.56.85.89.38 1.87.52 2.74-.78.98-1.56 1.96-2.35 2.94-.18-.13-.24-.26-.18-.39s.12-.2.18-.2z"
    ></path>
    <path
      fill="#fd2854"
      d="M18.49 30.46c.01.1 0 .22.04.28.1.13.24.23.36.34.3-2.11 1.21-2.54 3.46-2.23 1.31.18 2.89-1.61 4.35-2.51.52 1.18.52 2.26-.57 3.14-1.43 1.32-3.6 1.72-4.59 3.61-.12.39-.28.76-.51 1.1-.79.89-1.8 1.22-2.95 1.2-1.15-.47-1.93.19-2.76.86-.69.55-1.42 1.37-2.43.46-.38-2.74 2.44-6.62 5-6.85.23-.02.43.35.59.59z"
    ></path>
    <path
      fill="#fc3250"
      d="M18.49 30.46c-2.91.66-5.2 3.46-5.28 6.46-.34 1.2-1.12 1.9-2.35 2.09a1.35 1.35 0 01-.73-.35c-.47-1.53.05-2.98.28-4.34-.89 1.3-1.86 2.67-3.91 1.89-.4-.66-.39-1.36-.15-2.07 1.41-1.77 3.59-2.62 5.09-4.27.78-.2 1.56-.39 2.34-.59.19 1.57 1.12.99 1.8.53 1.02-.68 1.93-1.52 2.89-2.29v2.93z"
    ></path>
    <path
      fill="#fd3a52"
      d="M11.45 29.88c-.51 2.57-2.85 3.06-4.69 4.11-.5-.59-.5-1.18 0-1.76 2.09-.77 2.73-3.2 4.69-4.11v1.77z"
    ></path>
    <path
      fill="#f81454"
      d="M25.51 29.28c.98-.74 1.38-1.71 1.19-2.93h.58c-.37 2.12 1.76.91 2.34 1.76.2 1.43-.25 2.76-.58 4.11v.59c-.62 1.22-1.42 2.17-2.99 1.88-.42-.4-.55-.92-.66-1.46-.3-1.12.11-2.19.24-3.28-.04-.23-.08-.45-.13-.68z"
    ></path>
    <path
      fill="#e55899"
      d="M44.29 50.45c-2.9.92-2.73 3.69-3.51 5.88-1.65-.03-2.42 1.44-3.63 2.17-2.25.78-3.93 2.89-6.54 2.86-1.62-1.56-.32-3.04.13-4.54 2.04-2.63 5.59-3.24 7.69-5.78 2.49-.28 3.8-2.53 5.86-3.52v2.94z"
    ></path>
    <path
      fill="#fd444b"
      d="M6.17 24c1.96 2.48 1.77 3.56-1.18 6.47-1.23 1.02-2.54 1.91-4.1 2.35V29.3c1.74-1.24 3.25-2.68 4.1-4.7.39-.2.78-.39 1.17-.59z"
    ></path>
    <path
      fill="#f01657"
      d="M26.11 34.58c.98-.59 1.96-1.18 2.93-1.76v2.94c-1.17.79-2.34 1.57-3.52 2.36-.75-1.34-.31-2.47.59-3.54z"
    ></path>
    <path
      fill="#ea518e"
      d="M38.43 51.03c-.15 1.25-.77 1.95-1.95 2.58-1.76.95-3.66 1.78-5.09 3.29-1.91 1.24-3.33 3.37-5.97 3.31-.64-.25-.9-.73-.89-1.39.06-.45.27-.81.6-1.12 1.14-.85 2.17-1.84 3.55-2.35 1.93-.72 3.09-2.15 3.5-4.19.22-.39.53-.64.97-.72 1.92-.42 3.24-2.19 5.28-2.36v2.94z"
    ></path>
    <path
      fill="#f04e85"
      d="M33.15 50.45l-.58.58c-.49.87-1.26 1.43-2.11 1.92-2.11 1.25-3.85 2.86-4.93 5.12l-.59.59c-.77.6-1.26 1.91-2.61 1.01-.78-1.39.14-2.34.85-3.35v-1.76c2.62-.56 4.28-2.75 6.58-3.85 1.11-.53 2.11-1.32 3.39-1.44v1.18z"
    ></path>
    <path
      fill="#fd4053"
      d="M6.76 36.34c1.72-.63 3.12-1.68 4.08-3.35.02 2.01.05 3.89-.56 5.7-.18 2.21-1.59 3.42-4.1 3.53-.15-.87-.38-1.24-1.28-.5-1.26 1.04-2.66 1.91-4 2.85v-4.11c1.61-.64 2.75-1.94 4.1-2.94.53-.48.6-1.64 1.76-1.17z"
    ></path>
    <path
      fill="#fd434e"
      d="M6.76 36.34c-.59.39-1.17.78-1.76 1.17v-5.88c.58.22 1.63-.97 1.76.59v4.11z"
    ></path>
    <path
      fill="#fd4359"
      d="M6.17 42.22c1.51-1 3.31-1.68 4.1-3.53h.59c.86.92.78 1.99.53 3.1-1.47 2.53-4.09 3.64-6.4 5.13-.25.47-.69.58-1.17.59-.43-2.3.32-4.08 2.35-5.29z"
    ></path>
    <path
      fill="#fc3b5e"
      d="M5 46.92c1.36-2.31 3.89-3.15 5.86-4.71.25.23.4.52.47.85-.19 2.09.09 4.27-1.05 6.2-1.12.69-.06 2.25-1.17 2.94-.1-.25-.21-.5-.31-.75-1.13 3.25-4.41 4.1-6.72 6.03 1.49-2.48 3.31-4.8 3.36-7.91.02-.94.46-1.89-.43-2.66z"
    ></path>
    <path
      fill="#f14474"
      d="M19.07 55.15c-1.52.72-.48 3.58-2.93 3.53-2 .02 0-1.96-1.17-2.35-.3-.89.54-1.1.93-1.58 1.05-.47 1.75-1.77 3.18-1.35 1.96-1.17 3.91-2.35 5.87-3.52-1.22 2.59-3.31 4.2-5.87 5.29z"
    ></path>
    <path
      fill="#aa78ee"
      d="M57.78 78.07c1.61-1.66 3.84-1.76 5.87-2.35-1.43 2.09-3.79 1.87-5.87 2.35z"
    ></path>
    <path
      fill="#aa79ef"
      d="M54.85 79.24c.59-1.37 1.8-1.17 2.93-1.17-.81.8-1.9.92-2.93 1.17z"
    ></path>
    <path
      fill="#b47df5"
      d="M56.02 75.13c-.2-1.82.48-2.62 2.41-3.13 3.31-.88 6.33-2.79 9.91-2.74-.41 1.08-1.06 1.68-2.32 1.89-3.61.59-6.8 2.33-10 3.99z"
    ></path>
    <path
      fill="#f73a6b"
      d="M16.73 52.21c-.04-.83-.08-1.66-.14-2.65-1.48.75-2.42 2.03-3.97 2.06-.95-.9-.2-1.41.52-1.64 1.95-.62 3.02-2.17 4.17-3.65.39-.91.92-1.61 2.06-1.4.98.58.92 1.5.79 2.45-.29 1.17-.51 2.38-1.68 3.08v.58c-.29.84-.69 1.51-1.76 1.18z"
    ></path>
    <path
      fill="#f03467"
      d="M18.49 50.45c.73-.85.24-2.18 1.17-2.95 1.25-2.45 3.99-3.03 5.86-4.7v2.93c-2.34 1.57-4.4 3.58-7.03 4.71z"
    ></path>
    <path
      fill="#f84778"
      d="M16.73 52.21c.59-.39 1.17-.78 1.76-1.18.86.62.5 1.54.58 2.35-.98.59-1.95 1.18-2.93 1.76-.31-.39-.36-.78 0-1.17.2-.59.39-1.18.59-1.76z"
    ></path>
    <path
      fill="#f41d5a"
      d="M26.11 34.58c.21 1.25-.4 2.36-.59 3.54v.58c-1.48 1.64-2.98 3.24-5.43 3.27-1.51-1.01-.48-2.4-.55-3.63.38-1.31.14-2.91 1.68-3.68 1.73-.35 2.87-2.28 4.89-1.84v1.76z"
    ></path>
    <path
      fill="#f73167"
      d="M20.25 41.64c1.76-.98 3.52-1.96 5.28-2.95V42.8c-.81 3-4.16 2.82-5.86 4.7-.08-.82.27-1.74-.6-2.36-.75-.98-.14-1.86.19-2.76.22-.39.62-.55.99-.74z"
    ></path>
    <path
      fill="#e164ab"
      d="M30.8 61.02c2.31-.62 3.86-2.94 6.45-2.94.6-.74 1.26-1.38 2.34-1.17.36.61 1.27.85 1.17 1.76l-.59.59c-2.83.28-4.63 1.47-4.1 4.7-.03 1.68-1.26 2.33-2.55 2.83-2.29.88-4.36 2.14-6.33 3.58-1.38.89-2.59 2.14-4.38 2.25-.99-1.09-2.4-.01-3.48-.66-.6-1.59.1-2.63 1.48-3.31.87-.43 1.74-.85 2.54-1.41 2.5-1.44 5.24-2.61 6.39-5.61.29-.31.72-.37 1.06-.6z"
    ></path>
    <path
      fill="#dd5396"
      d="M39.6 56.91l-2.34 1.17c.68-1.57 1.7-2.47 3.52-1.76-.25.47-.69.57-1.18.59z"
    ></path>
    <path
      fill="#c479d9"
      d="M30.81 77.47c3.9-1.6 7.08-4.57 11.14-5.87.58 1.37-1.31 2.74 0 4.11-2.4 1.8-5.44 2.13-8.03 3.53-.92.5-2.03.67-2.53 1.76-.8.85-1.67 1.43-2.88.76-.1-1.9.17-3.59 2.29-4.29z"
    ></path>
    <path
      fill="#c27de0"
      d="M28.46 81.59c.98-.2 1.95-.39 2.93-.59.06 1.01-.1 1.91-1.17 2.35-1.14 1.02-2.2 2.13-3.52 2.94-2.27.35-3.58 2.62-5.86 2.94-.41-.98 1.05-1.96 0-2.94 1.04-2.87 3.77-4.56 7.62-4.7z"
    ></path>
    <path
      fill="#bc7de3"
      d="M26.7 86.29c.61-1.66 1.68-2.76 3.52-2.94 2.34.88 3.65-1.44 5.6-1.84.72-.15 1.35-.72 2.02-1.1-.13 2.66-1.56 4.21-4.06 5-1.81.57-3.55 1.36-5.33 2.05-.39.2-.78.39-1.17.59-.94-.34-1.05-.96-.59-1.76z"
    ></path>
    <path
      fill="#d371c1"
      d="M36.67 69.25c-.24 2.09-1.08 3.76-3.39 3.97-1.64.15-1.67 1.66-2.48 2.5-1.7 2.09-3.71 3.62-6.58 3.52-.62-1.4.24-2.55.61-3.78.55-1.32.77-2.82 2.08-3.71 1.85-1.4 3.69-2.84 6.24-2.49 1.17 2.93 2.34.39 3.52 0z"
    ></path>
    <path
      fill="#d966b1"
      d="M33.15 69.25c-1.98.94-4.16 1.46-5.87 2.94-1.76-.39.14-1.7-.58-2.35 2.03-1.45 4.05-2.94 6.51-3.6 1.3-.35 2.06-1.34 2.88-2.28.98.96 1.96-.75 2.93 0-.26 3.65-3.64 3.83-5.86 5.29z"
    ></path>
    <path
      fill="#ad81f2"
      d="M24.35 92.17c1.17-.39 2.34-.78 3.52-1.17.09 1.02-.84 1.53-1.17 2.35-.79-.37-3.04 2.18-2.34-1.17z"
    ></path>
    <path
      fill="#ca79d3"
      d="M24.35 79.24c2.15-1.17 4.3-2.35 6.45-3.52v1.75c-1.31 1.07-1.13 2.99-2.35 4.12-2.67 1.36-5.87 1.85-7.62 4.7-2.45 1.71-4.82 3.57-7.62 4.7.16-.59.93-1.18 0-1.77-.28-1.44.74-2.24 1.52-3.17.75-.56 1.56-1.05 2.32-1.59 1.81-1.53 4.43-1.94 5.74-4.17.41-.52.63-1.31 1.56-1.05z"
    ></path>
    <path
      fill="#fb315a"
      d="M10.28 49.27c.01-2.17-.2-4.36.59-6.46.45-.54.33-1.71 1.53-1.44 1.55.36 2.12-1.01 3.04-1.75.91-.73 1.75-1.78 2.79-.16.21 1.31-.16 2.57-.39 3.84a1.5 1.5 0 01-.53.69c-1.13.12-1.59 1.13-2.34 1.76-1.2.95-2.45 1.83-3.52 2.94-.39.2-.78.39-1.17.59z"
    ></path>
    <path
      fill="#f2235a"
      d="M17.31 43.98v-.59c.4-1.15 1.04-1.84 2.34-1.17l-.6 2.93c-.58.39-1.16.79-1.75 1.18-.57-.78-.02-1.56 0-2.35z"
    ></path>
    <path
      fill="#fc3b5e"
      d="M11.45 48.68c.66-1.59 1.92-2.47 3.52-2.94-.78 1.45-2.06 2.3-3.52 2.94z"
    ></path>
    <path
      fill="#f13a67"
      d="M16.14 53.98v1.17c-.39.39-.78.78-1.18 1.17-1.37.79-2.73 1.57-4.1 2.36-.6-1.2.3-1.78 1.07-2.28 1.36-.87 2.8-1.62 4.2-2.43z"
    ></path>
    <path
      fill="#d27acb"
      d="M14.97 86.29c-.59.98-1.17 1.95-1.76 2.93-1.37.78-2.74 1.57-4.1 2.35-.09-3-1.54-.47-2.34-.58-.09-.86.19-1.62.59-2.35 1.95-2.23 4.65-3.18 7.25-4.31 1.23.44.54 1.25.37 1.96z"
    ></path>
    <path
      fill="#d674be"
      d="M14.97 84.53l-7.62 4.11c-.01-.48-.11-.92-.59-1.18.92-1.6 3.13-1.41 4.11-2.94 1.53-1.66 2.92-3.57 5.59-3.26.56 1.57-.35 2.46-1.49 3.26zM3.24 89.82c.43-1.47 1.6-2.01 2.94-2.35-.26 1.68-1.93 1.59-2.94 2.35z"
    ></path>
    <path
      fill="#ea5b95"
      d="M22.59 59.85c.81-.33 1.33-1.26 2.34-1.19.01.49.11.93.59 1.18.7 1.69-.87 2.84-1 4.34-.15 1.37-.98 2.31-1.93 3.19-2.8.96-5.07 3.32-8.31 3.09-.18-1.75.5-3.1 1.86-4.16.2-.39.39-.78.59-1.17 1.8-1.93 4.55-2.81 5.87-5.28z"
    ></path>
    <path
      fill="#ef5388"
      d="M22.59 59.85c-.33 3.57-3.45 4.04-5.87 5.28-.16-.45.11-1.62-.52-1.24-2.89 1.71-1.73-.6-1.82-1.7 2.47-2.65 5.82-3.99 8.8-5.88-.19 1.18-.8 2.29-.59 3.53z"
    ></path>
    <path
      fill="#eb639b"
      d="M16.14 66.31c-.59 1.37-1.17 2.75-1.76 4.12.59 1.14 1.32 2.31-.59 2.94-1.02-.09-1.53.85-2.34 1.17v-1.77c.72-1.98.98-4.15 2.35-5.87.78-.2 1.56-.4 2.34-.59z"
    ></path>
    <path
      fill="#da6fb7"
      d="M26.7 69.84c1.2.53-.11 1.69.58 2.35-1.3.82-1.09 2.39-1.76 3.52-1.76 1.29-3.25 3.08-5.66 3.23-2.41-1.29-4.25.43-6.24 1.22-1.72.67-2.22.22-1.58-1.51.2-.2.39-.4.59-.59 1.34-1.66 2.8-3.15 5.12-3.28 1.23.84 2.31 1.12 2.76-.79.26-1.11 1.15-1.48 2.09-1.81 1.56-.44 2.85-1.38 4.11-2.35z"
    ></path>
    <path
      fill="#e664a4"
      d="M17.9 75.13c-2.05.45-3.41 2.15-5.27 2.93l1.17-4.69c.98-.82.45-1.95.59-2.94 2.54-1.17 5.08-2.35 7.63-3.52.83-.54 1.37-.21 1.76.59-.36.91-1.18 1.36-2 1.52-1.84.38-2.29 1.53-2.1 3.17-.23 1.19-.56 2.33-1.76 2.94z"
    ></path>
    <path
      fill="#d972ba"
      d="M12.04 78.65c.68-.07-.66 2.26 1.25.96 1.68-1.14 3.57-2.12 5.79-1.78.5.08.54.43.58.82.05 2.01-.49 3.42-2.93 2.94-2.54-.19-3.67 2.44-5.86 2.94v-1.76c-.58-.2.06-1.63-1.17-1.17.34-1.33.87-2.51 2.35-2.94z"
    ></path>
    <path
      fill="#da6fb7"
      d="M9.69 81.59c1.43-.66 1.13.43 1.17 1.17-.64.27-.81 1.52-1.75.95-.23-.14-.02-1.01 0-1.54l.59-.59z"
    ></path>
    <path
      fill="#fc2d55"
      d="M12.62 41.63c-.91-.1-1.15.82-1.76 1.17v-.59c0-1.17 0-2.35.01-3.52.78-.59 1.56-1.18 2.34-1.76.94-.36 1.53-1.14 2.26-1.77.61-.52 1.66-1.77 2.44.01.84 1 .78 2.2-.18 2.79-1.81 1.1-3.03 2.98-5.1 3.68z"
    ></path>
    <path
      fill="#f62354"
      d="M12.62 41.63c.73-1.59 2.37-2.22 3.64-3.14 1.27-.92 1.81-1.86 1.65-3.32.98-.39 1.95-.79 2.93-1.18.52.23.9.53.59 1.18-1.45.56-.7 1.99-1.17 2.94-.64.68-1.32 1.27-2.35 1.17-2.25-.32-2.57 2.43-4.49 2.82-.75.15-.71.02-.79-.47z"
    ></path>
    <path
      fill="#fb1c56"
      d="M21.42 35.17c-.2-.39-.39-.78-.59-1.18v-1.18a56.32 56.32 0 005.28-3.52c1.25 1.18-1.18 2.35 0 3.53-1.67.57-2.81 2.2-4.69 2.35z"
    ></path>
    <path
      fill="#f50951"
      d="M26.12 29.29c-1.34 1.81-2.87 3.33-5.28 3.52.88-2.07 3.09-2.39 4.68-3.53.2 0 .41 0 .61.01z"
    ></path>
    <path
      fill="#e9599b"
      d="M23.77 63.96c.59-1.37 1.17-2.74 1.76-4.11 2.24-.41 3.52-2.73 5.86-2.94-.37 1.35-1.34 2.61-.59 4.12l-.59.59c-2.06 1.04-3.94 2.56-6.45 2.35z"
    ></path>
    <path
      fill="#ea4980"
      d="M25.53 58.07c.16-4.53 4.23-5.16 7.03-7.04.29 2.09-.77 3.44-2.46 4.42-1.52.88-3.05 1.75-4.57 2.62z"
    ></path>
    <path
      fill="#fa2b5e"
      d="M17.9 39.28c.6-.76 1.41-1.08 2.35-1.17.45 1.18-.97 2.36 0 3.54-.2.19-.39.38-.59.58-.8.36-1.8.3-2.34 1.17-.19-1.43.25-2.76.59-4.11z"
    ></path>
    <path
      fill="#e25f9e"
      d="M23.77 63.96c2.07-.99 4.05-2.26 6.45-2.35-.64 3.63-3.62 4.67-6.45 5.88-.59-.2-1.17-.39-1.76-.59.84-.83 2.09-1.41 1.76-2.94z"
    ></path>
    <path
      fill="#dc6bb3"
      d="M17.9 75.13c.67-.93.4-2.42 1.76-2.94.98-.93 1.96-2.21 2.93 0-1.7.79-1.18 3.05-2.96 4.06-1.96 1.11-1.32-.55-1.74-1.12z"
    ></path>
    <path
      fill="#d176c5"
      d="M16.73 81.59c1.81-.14 2.25-1.66 2.93-2.94 2.13-.63 3.64-2.5 5.86-2.94-.39 1.18-.78 2.35-1.17 3.53-.39.39-.78.79-1.17 1.18-.99 1.05-2.08 1.95-3.45 2.45-1.13.41-1.94 1.15-2.42 2.25-.81.33-1.32 1.26-2.34 1.18-.68-.59.19-1.18 0-1.77-.05-1.36.75-2.21 1.76-2.94z"
    ></path>
    <path
      fill="#d07cd6"
      d="M17.31 85.12c-.03-.91.07-1.78 1.01-2.25 1.62-.82 3.24-1.63 4.86-2.45.27 1.43-.85 1.65-1.76 2.16-1.41.79-2.74 1.69-4.11 2.54z"
    ></path>
  </svg>
  ),
  viewBox: '0 0 512 512',
})
